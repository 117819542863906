<template>
  <div class="dropdown-select">
    <!-- Icon is hardcoded for now -->
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 62 52.7"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M61.9 49.5L53.5 31c-.1-.2-.3-.4-.5-.5l-10.4-4.7-.8 1.8 10.1 4.6L59 48l-12.3-5.5-3.7-9.7c-.2-.5-.8-.8-1.3-.6-.5.2-.8.8-.6 1.3l3.6 9.3L32 50v-7.2h-2V50l-12.6-7.1 3.6-9.3c.2-.5-.1-1.1-.6-1.3-.5-.2-1.1.1-1.3.6l-3.7 9.7L3 48l7.2-15.8 10.1-4.5-.8-1.8L9 30.5c-.2.1-.4.3-.5.5L.1 49.5c-.2.4-.1.8.2 1.1.3.3.7.4 1.1.3L16 44.4l14.4 8.2c.2.1.3.1.5.1s.3 0 .5-.1l14.4-8.2 14.6 6.5c.4.2.8.1 1.1-.2s.5-.8.4-1.2z"
      />
      <path
        class="st0"
        d="M31 39.5c.4 0 .7-.2.9-.5.5-.8 11.7-19.8 11.7-26.4C43.5 5.6 37.9 0 31 0c-7 0-12.6 5.6-12.6 12.6 0 6.6 11.2 25.6 11.7 26.4.2.3.5.5.9.5zM31 2c5.8 0 10.6 4.7 10.6 10.6 0 4.6-7 17.7-10.6 23.9-3.6-6.2-10.6-19.3-10.6-23.9C20.4 6.7 25.1 2 31 2z"
      />
      <path
        class="st0"
        d="M31 19.6c3.9 0 7-3.1 7-7s-3.1-7-7-7-7 3.1-7 7c0 1.9.7 3.6 2 4.9 1.4 1.3 3.1 2.1 5 2.1zm-5-7c0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5 0 2.7-2.2 5-5 5-1.3 0-2.6-.5-3.5-1.5-1-.9-1.5-2.2-1.5-3.5z"
      />
    </svg>

    <Dropdown
      :options="options"
      :placeholder="placeholder"
      @select="
        stepsModule.toggleOption($event, stepsModule.state.stepCurrent)
      "
    />

    <!-- for region section -->
    <Input
      v-if="isPostalshown"
      class="dropdown-select__input"
      :placeholder="this.$t(['REGION', 'postcodePlaceholder'])"
      @on-change="setPostcode"
      :value="postcode"
      :error="isPostcodeError"
    />
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IOption, IStepSelectRegion } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Input from './Input.vue'
import Dropdown from './Dropdown.vue'
import { FormModule } from '@/composables/use-form';

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })

  formModule = prop({
    type: Object as () => FormModule,
    required: true,
  })
}

@Options({
  components: {
    Dropdown,
    Input,
  },
})
export default class DropdownSelect extends mixins(Vue.props(Props), TranslationHandler) {
  postcode: string = ''

  get placeholder(): string {
    return this.$t([this.stepsModule.state.stepCurrent.type, 'placeholder'])
  }
  
  get selectCurrent(): string[] | undefined {
    return this.stepsModule.state.selected[this.stepsModule.state.stepCurrent.type]
  }

  get isPostalshown(): boolean {
    return !!this.selectCurrent && this.selectCurrent[0] === IStepSelectRegion.DE
  }

  get isPostcodeError(): boolean {
    return this.postcode.length !== 5 && this.postcode !== ''
  }

  setPostcode(postcode: string) {
    this.postcode = postcode

    this.formModule.setContactDetails({ postcode: this.postcode })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.dropdown-select {
  display: flex;
  flex-direction: column;

  svg {
    margin: 0 auto $spacing-1 auto;
    width: 60px;
    fill: $blue-1;
  }

  &__input {
    margin-top: 20px;
  }
}
</style>
