
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler';
import Button from './Button.vue';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true
  });
}

@Options({
  components: {
    Button
  }
})
export default class StepSplash extends mixins(
  Vue.props(Props),
  TranslationHandler
) {}
