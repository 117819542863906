<template>
  <div class="home">
    <div class="home__mood">
      <div class="home__mood__copy">
        <h2
          v-html="$t([stepsModule.state.stepCurrent.type, 'hero', 'headline'])"
        ></h2>
        <h3>
          {{ $t([stepsModule.state.stepCurrent.type, "hero", "subline"]) }}
        </h3>
      </div>
      <img :src="stepsModule.state.imageMap['homeLeft']" alt="" />
    </div>
    <div class="home__select">
      <img :src="stepsModule.state.imageMap['homeRight']" alt="" />
      <p class="home__select__headline">
        {{ $t([stepsModule.state.stepCurrent.type, "headline"]) }}
      </p>
      <span class="home__select__hint">
        {{ $t(["general", "multipleChoice"]) }}
      </span>

      <!-- Tiles -->
      <slot></slot>
      <!-- Tiles end -->

      <Button
        class="home__button"
        @on-click="stepsModule.next"
        :text="$t(['general', 'buttonNext'])"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
      />

      <p class="home__select__skip-steps">
        {{ $t(["general", "contact"]) }}
        <span @click="stepsModule.nextContact">{{
          $t(["general", "contactClick"])
        }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler'
import Button from './Button.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button,
  }
})
export default class StepHome extends mixins(Vue.props(Props), TranslationHandler) {
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.home {
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    min-height: 800px;
  }

  > div {
    @media only screen and (min-width: 1200px) {
      flex: 1;
    }
  }

  &__mood {
    overflow: hidden;
    color: $white;
    font-size: 32px;
    line-height: 0;
    position: relative;

    img {
      display: block;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
      object-position: 0 10%;
      @media only screen and (min-width: 1200px) {
        object-position: 50% 50%;
        height: -webkit-fill-available;
        max-height: 970px;
      }
    }

    &__copy {
      padding: 40px 20px;
      position: absolute;
      line-height: initial;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h2 {
        font-weight: 600;
        @media only screen and (min-width: 1200px) {
          font-size: 3.5vw;
        }
      }
      h3 {
        font-weight: 400;
      }
    }

    &::after {
      content: "";
      height: 100%;
      width: 100%;
      background: linear-gradient(135deg, #002856, #110000);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }

    &::before {
      content: "";
      height: 120px;
      width: 110%;
      display: block;
      background: white;
      position: absolute;
      bottom: -60px;
      z-index: 1;
      transform: translate(0px, 1px) skew(0deg, -5deg);

      @media only screen and (min-width: 1200px) {
        content: "";
        height: 110%;
        width: 120px;
        display: block;
        background: white;
        position: absolute;
        right: -60px;
        bottom: 0;
        z-index: 1;
        transform: translate(0px, 1px) skew(-5deg, 0deg);
      }
    }
  }

  &__button {
    margin: 30px 0;
  }

  &__select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 400px;
    }

    &__headline {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      line-height: 32px;
      margin-bottom: 20px;
      max-width: 530px;
      padding: 0 20px;
    }
    &__hint {
      font-size: 14px;
      color: $gray-2;
      margin-bottom: $spacing-3;
    }

    &__skip-steps {
      font-size: 14px;
      color: $gray-2;
      font-weight: 400;
      text-align: center;

      span {
        text-decoration: none;
        border-bottom: 1px solid $gray-2;
        color: inherit;
        cursor: pointer;
      }
    }
  }
}
</style>
