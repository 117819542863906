<template>
  <label class="switch">
    <input type="checkbox" v-model="isOpen" />
    <span class="check"></span>
    <span class="label" v-html="text"></span>
  </label>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  text = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    isOpen(isOpen: string) {
      this.$emit('on-change', isOpen)
    }
  }
})
export default class Switch extends Vue.props(Props) {
  isOpen: boolean = false

  created() {
    this.isOpen = this.value
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";
.switch {
  margin: 5px 0;
  display: flex;
  .label {
    font-weight: 500;
    color: $gray-2;
    transition: all 0.2s linear;
    ::v-deep(a) {
      text-decoration: none;
      border-bottom: 1px solid $gray-2;
      color: inherit;
      transition: all 0.2s linear;
    }
  }
  .check {
    width: 40px;
    height: 14px;
    background: $gray-1;
    display: inline-block;
    border-radius: 20px;
    position: relative;
    transition: all 0.2s linear;
    margin-right: 10px;
    &:before {
      content: "";
      position: absolute;
      height: 22px;
      width: 22px;
      display: block;
      border-radius: 20px;
      border: 2px solid $gray-2;
      background: white;
      box-sizing: border-box;
      transform: translate(10%, -50%);
      top: 50%;
      right: 50%;
      transition: all 0.2s linear;
    }
  }

  input {
    display: none;
    &:checked ~ .label {
      color: $black;
      a {
        border-bottom: 1px solid $black;
      }
    }
    &:checked ~ .check {
      width: 40px;
      height: 14px;
      background: $black;
      display: inline-block;
      border-radius: 20px;

      &:before {
        content: "";
        position: absolute;
        height: 22px;
        width: 22px;
        display: block;
        border-radius: 20px;
        border: 2px solid $black;
        background: $white;
        box-sizing: border-box;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
      }
    }
  }
}
</style>
