
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { mixins, Options, Vue, prop } from 'vue-class-component';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({})
export default class Overlay extends mixins(Vue.props(Props), TranslationHandler) {
  isTooltipShown: boolean = false

  $refs!: {
    tooltip: HTMLElement,
  }

  mounted() {
    document.addEventListener('click', this.bindClick)
  }

  unmounted() {
    document.removeEventListener('click', this.bindClick)
  }

  bindClick(event: Event) {
    const $tooltip: HTMLElement | undefined = this.$refs.tooltip

    if ($tooltip === undefined || this.isTooltipShown === false) { return }

    if ($tooltip.contains((event.target as HTMLElement)) === false) {
      this.isTooltipShown = false
    }
  }
}
