<template>
  <div class="calendar">
    <div class="calendar__frame" ref="calendar"></div>
  </div>
</template>

<script lang="ts">
import { FormModule } from '@/composables/use-form';
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
  formModule = prop({
    type: Object as () => FormModule,
    required: true,
  })
}

declare global {
  interface Window {
    Calendly: any
  }
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}


@Options({})
export default class Calendar extends mixins(TranslationHandler, Vue.props(Props)) {
  $refs!: {
    calendar: HTMLElement
  }

  scriptIsLoaded: boolean = false
  
  async mounted() {
    await this.injectScript()

    if ((window as any).Cypress !== undefined) { // test workaround
      this.stepsModule.state.selected[IStepType.CALENDAR] = ['requested']
    }

    window.Calendly.initInlineWidget({
      parentElement: this.$refs.calendar,
      url: 'https://calendly.com/lvz?primary_color=0a2d4b',
      // url: 'https://calendly.com/andreas-takeit?primary_color=0a2d4b',
      prefill: {
        name: `${this.formModule.state.contactDetails.firstName} ${this.formModule.state.contactDetails.lastName}`,
        email: this.formModule.state.contactDetails.email,
      }
    })
    
    window.addEventListener('message', (e) => {
      if (e.data.event && 
        e.data.event.includes('calendly') && 
        e.data.event === 'calendly.event_scheduled') {
        this.stepsModule.state.selected[IStepType.CALENDAR] = ['requested']
      }
    })
  }

  async injectScript() {
    const url = 'https://assets.calendly.com/assets/external/widget.js'

    const $script = document.createElement('script')
    $script.src = url

    document.body.appendChild($script)

    while (!this.scriptIsLoaded) {
      if (window.Calendly !== undefined) {
        this.scriptIsLoaded = true
      }
      await sleep(50)
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar {
  height: 710px;
  position: relative;

  &__frame {
    height: 710px;
    width: 100%;
    max-width: 600px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 32px;
  }
}
</style>
