<template>
  <label class="checkbox" @click="onClick">
    <input type="checkbox" :checked="value" />
    <span class="check">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.2 11.4">
        <path
          d="M4.7 11.4c-.3 0-.5-.1-.7-.3L.3 7.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2c.6-2.6 2.3-7.1 6.9-8.3.5-.1 1.1.2 1.2.7.1.5-.2 1.1-.7 1.2-5 1.3-5.7 8.3-5.7 8.4 0 .4-.3.7-.6.9-.2.1-.3.2-.4.2z"
          fill="#d2b887"
        />
      </svg>
    </span>
    <span class="label" v-html="text"></span>
  </label>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  text = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['on-click'],
})
export default class Checkbox extends Vue.props(Props) {
  onClick(event: Event) {
    event.preventDefault()

    this.$emit('on-click')
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";
.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .check {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    border: 1px solid $blue-1;
    display: inline-block;
    padding: 2px;
    box-sizing: border-box;

    svg {
      display: none;
    }
  }

  .label {
    margin-top: 1px;
  }

  input {
    display: none;
    &:checked ~ .check {
      border-color: $brown;
      svg {
        display: block;
      }
      &:after {
        visibility: visible;
      }
    }
  }
}
</style>
