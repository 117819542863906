
import { StepsModule } from '@/composables/use-steps';
import { OptionsHandler } from '@/mixins/options-handler';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStep } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Dropdown from './Dropdown.vue'
import Button from './Button.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Dropdown,
    Button,
  }
})
export default class Overview extends mixins(Vue.props(Props), TranslationHandler, OptionsHandler) {
  get steps(): (IStep | undefined)[] {
    return Object
      .keys(this.stepsModule.state.selected)
      .map((stepType) => {
        return this.stepsModule.state.steps.find((s) => s.type === stepType)
      })
      .filter((step) => step !== undefined)
  }

  getOptions(step: IStep) {
    return this.$optionsGet(step, this.stepsModule.state.selected, this.$t)
  }
}
