
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  progress = prop({
    type: Number
  })
}

@Options({
  
})
export default class ProgressBar extends Vue.props(Props) {
  get style() {
    return {
      width: `${this.progress}%`
    }
  }
}
