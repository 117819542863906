<template>
  <div class="contact">
    <div class="contact__inner">
      <div class="contact__inputs">
        <Dropdown
          :options="salutations"
          :placeholder="this.$t(['form', 'salutation'])"
          @select="setFormValue('salutation', $event)"
        />
        <Input
          :placeholder="this.$t(['form', 'firstName'])"
          @on-change="setFormValue('firstName', $event)"
          :value="form.firstName"
          :error="getIsFieldError('firstName', form.firstName)"
        />
        <Input
          :placeholder="this.$t(['form', 'lastName'])"
          @on-change="setFormValue('lastName', $event)"
          :value="form.lastName"
          :error="getIsFieldError('lastName', form.lastName)"
        />
        <Input
          :placeholder="this.$t(['form', 'email'])"
          type="email"
          @on-change="setFormValue('email', $event)"
          :value="form.email"
          :error="getIsFieldError('email', form.email)"
        />
        <Input
          :placeholder="this.$t(['form', 'phone'])"
          type="tel"
          @on-change="setFormValue('phone', $event)"
          :value="form.phone"
          :error="getIsFieldError('phone', form.phone)"
        />
      </div>
      <Textarea
        :placeholder="this.$t(['form', 'message'])"
        @on-change="setFormValue('message', $event)"
        :value="form.message"
        :error="getIsFieldError('message', form.message)"
      />
      <Switch
        :text="this.$t(['form', 'mailback'])"
        @on-change="setFormValue('mailback', $event)"
        :value="form.mailback"
      />
      <Switch
        :text="this.$t(['form', 'callback'])"
        @on-change="setFormValue('callback', $event)"
        :value="form.callback"
      />
      <Switch
        :text="this.$t(['form', 'legalities'])"
        @on-change="setFormValue('legalities', $event)"
        :value="form.legalities"
      />
      <div @click="submit">
        <Button
          id="button-next"
          :text="$t(['form', 'button'])"
          :disabled="!formIsValid"
          :icon="true"
        />
      </div>
      <div class="contact__loader" v-if="isLoading">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 38 38"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <Teleport to="body">
      <dialog ref="dialog" class="dialog">
        <button
          @click="$refs.dialog.close()"
          type="reset"
          class="dialog__close"
        >
          <svg
            title="Schließen"
            aria-labelledby="svg-close"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <title id="svg-close">Schließen</title>
            <path
              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
            />
          </svg>
        </button>
        <div class="dialog__inner">
          <h2>Datenschutz</h2>
          <h3>Einleitung</h3>
          <p>
            Daten sind die Grundlage für uns, einen ausgezeichneten Service zu
            leisten. Unser wichtigstes Kapital ist aber das Vertrauen unserer
            Kunden. Die Kundendaten zu schützen und sie nur so zu nutzen, wie es
            unsere Kunden von uns erwarten und der Gesetzgeber vorschreibt, hat
            für uns höchste Priorität. Deshalb ist das Einhalten der
            gesetzlichen Bestimmungen zum Datenschutz für uns
            selbstverständlich. Ferner ist es uns wichtig, dass Sie jederzeit
            wissen, wann wir welche Daten speichern und wie wir sie verwenden.
          </p>
          <h3>Datenerhebung + Webtracking</h3>
          <p>
            Wir speichern Ihre persönlichen Daten über unsere Webseite nur dann,
            wenn Sie uns diese von sich aus angeben, z.B. im Rahmen einer
            Kontaktaufnahme über unser Webformular, einer Onlinebewerbung oder
            zur Durchführung eines Vertrags. Sie werden in den jeweiligen
            Eingabe- und Kontaktformularen über die Zwecke der Erhebung der dort
            erhobenen Daten informiert, alternativ erfolgt das in dieser
            Datenschutzerklärung.
          </p>
          <p>
            Personen unter 18 Jahren
            sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine
            personenbezogenen Daten an uns übermitteln.
          </p>
          <p>
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Ireland Limited, <i>mit Sitz in
              Gordon House, Barrow Street, Dublin 4, Irland</i>. Google
            Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die
            auf Ihrem Computer gespeichert werden und die eine Analyse der
            Benutzung der Website durch Sie ermöglichen. Die durch das Cookie
            erzeugten Informationen über Ihre Benutzung dieser Website werden in
            der Regel an einen Server von Google in den USA übertragen und dort
            gespeichert.
          </p>
          <p>
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch das Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
          </p>
          <h4>Widerspruch gegen Datenerfassung durch Google Analytics</h4>
          <p>
            Sie können alternativ die Erfassung durch Google Analytics auch
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten
            beim Besuch dieser Website verhindert:
            <a
              onclick="gaOptout(event);"
              href="https://starcar.de/datenschutz/#"
            >
              Google Analytics deaktivieren
            </a>.
          </p>
          <p>
            Das Opt-Out-Cookie gilt nur für diesen Browser und auch nur
            für diese Domain. Löschen Sie Ihre Cookies in diesem Browser, müssen
            Sie diesen Link erneut klicken.
          </p>
          <p>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
            finden Sie in der Datenschutzerklärung von Google:
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              target="_blank"
            >
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
          </p>
          <p>
            Wenn Sie unsere Webseite besuchen und weitere angebotene Google
            Services wie Google Maps nutzen, so gelten die
            Datenschutz-Bedingungen des jeweiligen Anbieters:
            <a
              href="https://www.google.com/intl/ALL/policies/privacy/index.html"
              target="_blank"
            >
              https://www.google.com/intl/ALL/policies/privacy/index.html
            </a>
          </p>
          <p>
            STARCAR selbst hat keinen Einfluss auf die Datenverwendung
            Ihrer Angaben durch den jeweiligen Service-Anbieter.
          </p>
          <p>
            Sie können diese Erhebung auch durch andere geeignete
            Zusatzprogramme / Skriptblocker wie z.B. Ghostery (
            <a
              href="https://www.ghostery.com/"
              title="Opens external link in new window"
              target="_blank"
              class="external-link-new-window"
            >
              https://www.ghostery.com/
            </a>
            ) unterbinden.
          </p>
          <h3>Generelle Serverprotokolle</h3>
          <p>
            Wir erheben und speichern automatisch in den Server-Log-Files
            Informationen, die Ihr Browser an uns übermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp / -version</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL (die zuvor besuchte Seite)</li>
            <li>Hostname des zugreifenden Rechners (IP Adresse)</li>
            <li>Uhrzeit der Serveranfrage</li>
          </ul>
          <p>
            Diese Daten sind für uns nicht bestimmten Personen zuordenbar. Eine
            Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen, die Daten werden zudem in zyklischen Abständen
            gelöscht. Diese Daten werden durch die Webserverfunktionalitäten
            bereits herstellerseitig erhoben und von uns nur in Ausnahmefällen
            aus Gründen der Absicherung unseres Webauftritts ausgewertet.
          </p>
          <h3>Datenverwendung</h3>
          <p>
            Die personenbezogenen Daten, die Sie uns über unsere Webseite oder
            per E-Mail mitteilen (z.B. Ihr Name und Ihre Adresse oder Ihre
            E-Mail-Adresse), werden nur zur Korrespondenz mit Ihnen und nur für
            den Zweck verarbeitet, zu dem Sie uns die Daten zur Verfügung
            gestellt haben.
          </p>
          <p>
            Die auf dieser Website gesammelten Informationen werden an die
            zuständige Einheit innerhalb unseres Unternehmens weitergeleitet.
            Wir versichern, dass wir Ihre personenbezogenen Daten darüber hinaus
            nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich
            verpflichtet wären oder Sie uns vorher Ihre Zustimmung gegeben
            haben.
          </p>
          <p>
            Soweit wir zur Durchführung und Abwicklung von
            Verarbeitungsprozessen Dienstleister in Anspruch nehmen, werden die
            Vertragsverhältnisse nach den Bestimmungen des
            Bundesdatenschutzgesetzes geregelt.
          </p>
          <h3>WhatsApp</h3>
          <p>
            Wenn Sie WhatsApp nutzen, um mit uns in Kontakt zu treten,
            verarbeiten wir Ihre Telefonnummer, Ihren Namen und weitere von
            Ihnen mitgeteilte Daten, um Ihre Anfrage zu beantworten und
            Fahrzeuge zu reservieren oder, wenn Sie ausdrücklich eingewilligt
            haben, um Ihnen werbliche Nachrichten zuzusenden.
          </p>
          <p>
            Für den Versand und Empfang von Nachrichten über unsere
            verschiedenen Kommunikationskanäle, einschließlich WhatsApp, nutzen
            wir den Dienst Superchat der SuperX GmbH, Oranienburger Str. 91,
            10178 Berlin („Superchat“). Die Daten werden in unserem Auftrag auf
            Servern von Superchat in Deutschland gespeichert.
          </p>
          <p>
            Betreiber des WhatsApp-Dienstes ist die WhatsApp Ireland Limited, 4
            Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
            („WhatsApp"), eine Konzerngesellschaft der Meta Platforms, Inc.
            (vormals Facebook). WhatsApp verarbeitet die Daten entsprechend
            seiner Datenschutzhinweise, die Sie
            <a
              href="https://www.whatsapp.com/legal/privacy-policy-eea?lang"
              target="_blank"
            >
              hier
            </a>
            abrufen können. Die Kommunikationsinhalte sind Ende-zu-Ende
            verschlüsselt. Gegebenenfalls erhebt WhatsApp auch weitere
            sogenannte „Metadaten“, die Informationen über die Identität von
            Absender und Empfänger enthalten können, sowie die Telefonnummer,
            Geräteinformationen und Informationen zur Nutzung von WhatsApp (z.B.
            Dauer und Häufigkeit). WhatsApp nutzt diese Daten auch für eigene
            Zwecke, wie z.B. die Verbesserung des WhatsApp-Dienstes. Weitere
            Informationen ergeben sich aus den
            <a
              href="https://www.whatsapp.com/legal/privacy-policy-eea?lang"
              target="_blank"
            >
              WhatsApp-Datenschutzhinweisen
            </a>.
            Wir haben keine Kenntnis von den Einzelheiten dieser
            Datenverarbeitung und auch keinen Einfluss darauf. Eine Weitergabe
            an weitere Empfänger innerhalb der Meta-Unternehmensgruppe in
            Ländern außerhalb der EU, die kein angemessenes Datenschutzniveau
            bieten (insbesondere in den USA), können wir nicht ausschließen. 
          </p>
          <p>
            Rechtsgrundlage für die Datenverarbeitung durch uns ist
          </p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. b) DS-GVO, wenn die Kommunikation der Anbahnung
              oder Abwicklung eines Vertragsverhältnisses dient;
            </li>
            <li>
              Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO, wenn Sie
              sich für den Newsletter-Versand per WhatsApp angemeldet haben
            </li>
            <li>
              sowie in allen anderen Fällen unser berechtigtes Interesse gemäß
              Art. 6 Abs. 1 lit. f) DS-GVO an der Bearbeitung Ihrer Anfrage.
            </li>
          </ul>
          <p>
            Soweit die Verarbeitung aufgrund Ihrer Einwilligung erfolgt, werden
            die Daten gelöscht, sobald Sie Ihre Einwilligung widerrufen haben.
            Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie im Chat
            eine Nachricht mit dem Inhalt “Stop” oder eine E-Mail an
            datenschutz@sces-group.de senden. 
          </p>
          <p>
            Ansonsten löschen wir Ihre Daten, sobald der Zweck der Verarbeitung
            weggefallen ist (z.B. die Anfrage abschließend beantwortet wurde).
            Falls der Löschung gesetzliche Aufbewahrungsfristen entgegenstehen,
            werden die Daten bis zum Ablauf der Aufbewahrungsfrist für die weitere
            Verwendung gesperrt. 
          </p>
          <h3>Telegram</h3>
          <p>
            Wenn Sie Telegram nutzen, um mit uns in Kontakt zu treten, verarbeiten
            wir Ihre Telefonnummer, Ihren Telegram-Nutzernamen, gegebenenfalls
            Ihren Namen und weitere von Ihnen mitgeteilte Daten, um Ihre Anfrage
            zu beantworten und Fahrzeuge zu reservieren oder, wenn Sie
            ausdrücklich eingewilligt haben, um Ihnen werbliche Nachrichten
            zuzusenden.
          </p>
          <p>
            Für den Versand und Empfang von Nachrichten über unsere verschiedenen
            Kommunikationskanäle, einschließlich Telegram, nutzen wir den Dienst
            Superchat der SuperX GmbH, Oranienburger Str. 91, 10178 Berlin
            („Superchat“). Die Daten werden in unserem Auftrag auf Servern von
            Superchat in Deutschland gespeichert.
          </p>
          <p>
            Betreiber des Telegram-Dienstes ist die Telegram Messenger LLP,
            71-75 Shelton Street, Covent Garden, London, United Kingdom
            („Telegram"). Telegram verarbeitet die Daten entsprechend seiner
            Datenschutzhinweise, die Sie
            <a 
              href="https://telegram.org/privacy?setln=de" 
              target="_blank"
            >
              hier
            </a>
            abrufen können. Die Kommunikation bei Telegram ist grundsätzlich
            nicht verschlüsselt. Wir weisen darauf hin, dass Telegram auf das
            Adressbuch des genutzten Endgerätes und die darin gespeicherten
            Kontaktdaten zugreift. Gegebenenfalls erhebt Telegram auch weitere
            sogenannte „Metadaten“, die Informationen über die Identität von
            Absender und Empfänger enthalten können, sowie die Telefonnummer,
            Geräteinformationen und Informationen zur Nutzung von Telegram (z.B.
            Dauer und Häufigkeit). Telegram nutzt diese Daten auch für eigene
            Zwecke, wie z.B. die Verbesserung des Telegram-Dienstes.
            Einzelheiten ergeben sich aus den
            <a 
              href="https://telegram.org/privacy?setln=de" 
              target="_blank"
            >
              Telegram-Datenschutzhinweisen
            </a>. 
            Die Daten werden von Telegram möglicherweise an weitere Empfänger
            innerhalb der Telegram-Unternehmensgruppe weitergegeben
            (insbesondere die Telegram Group Inc. und die Telegram FZ-LLC) und
            möglicherweise auch auf Servern in Ländern außerhalb der EU
            verarbeitet, die kein angemessenes Datenschutzniveau bieten. Wir
            haben keine Kenntnis von den Einzelheiten dieser Datenverarbeitung
            und auch keinen Einfluss darauf. 
          </p>
          <p>
            Rechtsgrundlage für die Datenverarbeitung durch uns ist
          </p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. b) DS-GVO, wenn die Kommunikation der Anbahnung
              oder Abwicklung eines Vertragsverhältnisses dient;
            </li>
            <li>
              Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO, wenn Sie
              sich für den Newsletter-Versand per Telegram angemeldet haben
            </li>
            <li>
              sowie in allen anderen Fällen unser berechtigtes Interesse gemäß
              Art. 6 Abs. 1 lit. f) DS-GVO an der Bearbeitung Ihrer Anfrage.
            </li>
          </ul>
          <p>
            Soweit die Verarbeitung aufgrund Ihrer Einwilligung erfolgt, werden
            die Daten gelöscht, sobald Sie Ihre Einwilligung widerrufen haben.
            Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie im Chat
            eine Nachricht mit dem Inhalt “Stop” oder eine E-Mail an
            datenschutz@sces-group.de senden. 
          </p>
          <p>
            Ansonsten löschen wir Ihre Daten, sobald der Zweck der Verarbeitung
            weggefallen ist (z.B. die Anfrage abschließend beantwortet wurde).
            Falls der Löschung gesetzliche Aufbewahrungsfristen entgegenstehen,
            werden die Daten bis zum Ablauf der Aufbewahrungsfrist für die
            weitere Verwendung gesperrt.
          </p>
          <h3>Facebook Messenger</h3>
          <p>
            Wenn Sie den Facebook Messenger nutzen, um mit uns in Kontakt zu
            treten, verarbeiten wir personenbezogene Daten, die für die
            Kontaktaufnahme, die Kommunikation und die Fahrzeugreservierung
            erforderlich sind. Facebook gewährt uns Zugriff auf Ihre
            „öffentlichen Informationen“, die z.B. Ihren Namen, Ihr Geschlecht,
            Ihre Facebook-ID und Ihre Profil- und Titelbilder beinhalten. Wir
            nutzen diese Informationen, um Ihre Anfrage zu beantworten oder,
            wenn Sie ausdrücklich eingewilligt haben, um Ihnen werbliche
            Nachrichten zuzusenden. 
          </p>
          <p>
            Für den Versand und Empfang von Nachrichten über unsere
            verschiedenen Kommunikationskanäle, einschließlich Facebook
            Messenger, nutzen wir den Dienst Superchat der SuperX GmbH,
            Oranienburger Str. 91, 10178 Berlin („Superchat“). Die Daten werden
            in unserem Auftrag auf Servern von Superchat in Deutschland
            gespeichert. 
          </p>
          <p>
            Facebook ist ein Dienst der Facebook Ireland Ltd., 4 Grand Canal
            Square, Dublin 2, Irland („Facebook“), ein Unternehmen der
            Meta-Gruppe. Facebook verarbeitet die Daten entsprechend seiner
            Datenschutzrichtlinie, die Sie
            <a
              href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&amp;entry=0"
              target="_blank"
            >
              hier
            </a>
            abrufen können. Facebook bietet die Möglichkeit einer
            Ende-zu-Ende-Verschlüsselung der Kommunikation an, die in den
            Einstellungen des Messengers aktiviert werden kann. Wir weisen
            darauf hin, dass Facebook gegebenenfalls so genannte „Metadaten“,
            die Informationen über die Identität von Absender und Empfänger
            enthalten können, sowie den Standort, Geräteinformationen und
            Informationen zur Nutzung des Facebook-Messengers (z.B. Dauer und
            Häufigkeit) erhebt. Facebook nutzt diese Daten auch für eigene
            Zwecke, wie z.B. die Verbesserung des Facebook-Messengers. Weitere
            Informationen ergeben sich aus den
            <a
              href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&amp;entry=0"
              target="_blank"
            >
              Facebook-Datenschutzhinweisen
            </a>. 
            Wir haben keine Kenntnis von den Einzelheiten dieser
            Datenverarbeitung und auch keinen Einfluss darauf. Eine Weitergabe
            an weitere Empfänger innerhalb der Meta-Unternehmensgruppe in
            Ländern außerhalb der EU, die kein angemessenes Datenschutzniveau
            bieten (insbesondere in den USA), können wir nicht ausschließen. 
          </p>
          <p>
            Rechtsgrundlage für die Datenverarbeitung durch uns ist
          </p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. b) DS-GVO, wenn die Kommunikation der Anbahnung
              oder Abwicklung eines Vertragsverhältnisses dient;
            </li>
            <li>
              Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO, wenn Sie
              sich für den Newsletter-Versand per Facebook-Messenger angemeldet
              haben
            </li>
            <li>
              sowie in allen anderen Fällen unser berechtigtes Interesse gemäß
              Art. 6 Abs. 1 lit. f) DS-GVO an der Bearbeitung Ihrer Anfrage.
            </li>
          </ul>
          <p>
            Soweit die Verarbeitung aufgrund Ihrer Einwilligung erfolgt, werden
            die Daten gelöscht, sobald Sie Ihre Einwilligung widerrufen haben.
            Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie im Chat
            eine Nachricht mit dem Inhalt “Stop” oder eine E-Mail an
            datenschutz@sces-group.de senden. 
          </p>
          <p>
            Ansonsten löschen wir Ihre Daten, sobald der Zweck der Verarbeitung
            weggefallen ist (z.B. die Anfrage abschließend beantwortet wurde).
            Falls der Löschung gesetzliche Aufbewahrungsfristen entgegenstehen,
            werden die Daten bis zum Ablauf der Aufbewahrungsfrist für die
            weitere Verwendung gesperrt. 
          </p>
          <h3>Instagram Direct Messenger</h3>
          <p>
            Wenn Sie den Instagram Direct Messenger nutzen, um mit uns in
            Kontakt zu treten, verarbeiten wir personenbezogene Daten, die für
            die Kontaktaufnahme, die Kommunikation und die Fahrzeugreservierung
            erforderlich sind. Instagram gewährt uns Zugriff auf den von Ihnen
            gewählten Nutzernamen. Wir nutzen diese Informationen, um Ihre
            Anfrage zu beantworten oder, wenn Sie ausdrücklich eingewilligt
            haben, um Ihnen werbliche Nachrichten zuzusenden. 
          </p>
          <p>
            Für den Versand und Empfang von Nachrichten über unsere verschiedenen
            Kommunikationskanäle, einschließlich Instagram Direct Messenger,
            nutzen wir den Dienst Superchat der SuperX GmbH, Oranienburger Str.
            91, 10178 Berlin („Superchat“). Die Daten werden in unserem Auftrag
            auf Servern von Superchat in Deutschland gespeichert. 
          </p>
          <p>
            Instagram ist ein Dienst der Facebook Ireland Ltd., 4 Grand Canal
            Square, Dublin 2, Irland, ein Unternehmen der Meta-Gruppe. Instagram
            verarbeitet die Daten entsprechend seiner Datenschutzrichtlinie, die
            Sie
            <a
              href="https://www.instagram.com/privacy/checks/?cookie_consent=1&amp;next=https%3A%2F%2Fprivacycenter.instagram.com%2Fpolicy%2F%3Fentry_point%3Dig_help_center_data_policy_redirect%26__coig_consent%3D1"
              target="_blank"
            >
              hier
            </a>
            abrufen können. Wir weisen darauf hin, dass Instagram gemäß der
            Informationen in der Instagram-Datenschutzrichtlinie die von seinen
            Nutzern bereitgestellte Inhalte, einschließlich der
            Kommunikationsinhalte, sowie weitere Informationen wie
            beispielsweise den Standort, Geräteinformationen und Informationen
            zur Nutzung von Instagram (z.B. Dauer und Häufigkeit) erhebt.
            Instagram, nutzt diese Daten auch für eigene Zwecke, wie z.B. die
            Verbesserung des Instagram-Dienstes. Weitere Informationen ergeben
            sich aus den
            <a
              href="https://www.instagram.com/privacy/checks/?cookie_consent=1&amp;next=https%3A%2F%2Fprivacycenter.instagram.com%2Fpolicy%2F%3Fentry_point%3Dig_help_center_data_policy_redirect%26__coig_consent%3D1"
              target="_blank"
            >
              Instagram-Datenschutzhinweisen
            </a>. 
            Wir haben keine Kenntnis von den Einzelheiten dieser
            Datenverarbeitung und auch keinen Einfluss darauf. Eine Weitergabe an
            weitere Empfänger innerhalb der Meta-Unternehmensgruppe (z.B. an
            Facebook) sowie externe Dritte (z.B. Werbepartner und
            Analysedienste)in Ländern außerhalb der EU, die kein angemessenes
            Datenschutzniveau bieten (insbesondere in denUSA), können wir nicht
            ausschließen.
          </p>
          <p>
            Rechtsgrundlage für die Datenverarbeitung durch uns ist
          </p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. b) DS-GVO, wenn die Kommunikation der Anbahnung
              oder Abwicklung eines Vertragsverhältnisses dient;
            </li>
            <li>
              Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO, wenn Sie
              sich für den Newsletter-Versand per Instagram-Messenger angemeldet
              haben
            </li>
            <li>
              sowie in allen anderen Fällen unser berechtigtes Interesse gemäß
              Art. 6 Abs. 1 lit. f) DS-GVO an der Bearbeitung Ihrer Anfrage.
            </li>
          </ul>
          <p>
            Soweit die Verarbeitung aufgrund Ihrer Einwilligung erfolgt, werden
            die Daten gelöscht, sobald Sie Ihre Einwilligung widerrufen haben.
            Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie im Chat
            eine Nachricht mit dem Inhalt “Stop” oder eine E-Mail an
            datenschutz@sces-group.de senden.
          </p>
          <p>
            Ansonsten löschen wir Ihre Daten, sobald der Zweck der Verarbeitung
            weggefallen ist (z.B. die Anfrage abschließend beantwortet wurde).
            Falls der Löschung gesetzliche Aufbewahrungsfristen entgegenstehen,
            werden die Daten bis zum Ablauf der Aufbewahrungsfrist für die
            weitere Verwendung gesperrt.
          </p>
          <h3>Google Business Messages</h3>
          <p>
            Wenn Sie über unser Unternehmensprofil bei Google oder einen anderen
            Google-Dienst (z.B. Maps) mit uns in Kontakt treten, verarbeiten wir
            personenbezogene Daten, die für die Kontaktaufnahme, die
            Kommunikation und die Fahrzeugreservierung erforderlich sind. Google
            gewährt uns Zugriff auf den von Ihnen gewählten Nutzernamen. Wir
            nutzen diese Informationen, um Ihre Anfrage zu beantworten oder,
            wenn Sie ausdrücklich eingewilligt haben, um Ihnen werbliche
            Nachrichten zuzusenden.
          </p>
          <p>
            Für den Versand und Empfang von Nachrichten über unsere
            verschiedenen Kommunikationskanäle, einschließlich Google Business
            Messages, nutzen wir den Dienst Superchat der SuperX GmbH,
            Oranienburger Str. 91, 10178 Berlin („Superchat“). Die Daten werden
            in unserem Auftrag auf Servern von Superchat in Deutschland
            gespeichert.
          </p>
          <p>
            Google Business Messages ist ein Dienst der Google Ireland Ltd.,
            Gordon House, Barrow Street, Dublin 4, Irland („Google“), ein
            Unternehmen des Google-Konzerns. Google verarbeitet die Daten
            entsprechend seiner Datenschutzrichtlinie, die Sie
            <a 
              href="https://policies.google.com/privacy?hl=de"
              target="_blank"
            >
              hier
            </a>
            abrufen können. Wir haben keine Kenntnis von den Einzelheiten dieser
            Datenverarbeitung und auch keinen Einfluss darauf. Eine Weitergabe an
            weitere Empfänger innerhalb der Google-Unternehmensgruppe in Ländern
            außerhalb der EU, die kein angemessenes Datenschutzniveau bieten
            (insbesondere in den USA), können wir nicht ausschließen.
          </p>
          <p>
            Rechtsgrundlage für die Datenverarbeitung durch uns ist
          </p>
          <ul>
            <li>
              Art. 6 Abs. 1 lit. b) DS-GVO, wenn die Kommunikation der Anbahnung
              oder Abwicklung eines Vertragsverhältnisses dient;
            </li>
            <li>
              Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO, wenn Sie
              sich für den Newsletter-Versand per Instagram-Messenger angemeldet
              haben
            </li>
            <li>
              sowie in allen anderen Fällen unser berechtigtes Interesse gemäß
              Art. 6 Abs. 1 lit. f) DS-GVO an der Bearbeitung Ihrer Anfrage.
            </li>
          </ul>
          <p>
            Soweit die Verarbeitung aufgrund Ihrer Einwilligung erfolgt, werden
            die Daten gelöscht, sobald Sie Ihre Einwilligung widerrufen haben.
            Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie eine
            E-Mail an datenschutz@sces-group.de senden.
          </p>
          <p>
            Ansonsten löschen wir Ihre Daten, sobald der Zweck der Verarbeitung
            weggefallen ist (z.B. die Anfrage abschließend beantwortet wurde).
            Falls der Löschung gesetzliche Aufbewahrungsfristen entgegenstehen,
            werden die Daten bis zum Ablauf der Aufbewahrungsfrist für die
            weitere Verwendung gesperrt.
          </p>
          <h3>Datenverwendung im Lizenznehmergeschäft</h3>
          <p>
            Lizenznehmer sind rechtlich selbstständige Unternehmen
            die mit der STAR CAR GmbH Kraftfahrzeugvermietung kooperieren. Auf
            dieser Website sind Lizenznehmer mit dem Wort „Lizenznehmer“
            gekennzeichnet. Für die weitere Verarbeitung Ihrer Daten durch diese
            Lizenznehmer sind allein die Lizenznehmer verantwortlich.
          </p>
          <p>
            Bei einer Buchung für einen Lizenznehmer, werden die auf dieser
            Website gesammelten Informationen an den jeweiligen Lizenznehmer
            weitergeleitet. Bei der Buchung einer Miete über diese Website bei
            einem unserer Lizenznehmer erklären Sie ausdrücklich Ihr
            Einverständnis zur Weitergabe der für diesen Mietvorgang relevanten
            Daten.
          </p>
          <h3>Einwilligung und Widerruf, Recht auf Auskunft</h3>
          <p>
            Wenn Sie uns personenbezogene Daten überlassen haben, können Sie
            diese jederzeit wieder löschen lassen. Daten für Abrechnungs- und
            buchhalterische Zwecke sind von einer Kündigung/einem Widerruf bzw.
            von einer Löschung nicht berührt.
          </p>
          <p>
            Personenbezogene Daten, die uns über unsere Website mitgeteilt
            worden sind, werden nur so lange gespeichert, bis der Zweck erfüllt
            ist, zu dem sie uns anvertraut wurden. Soweit handels- und
            steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann die
            Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.
          </p>
          <p>
            Sollten Sie mit der Speicherung Ihrer personenbezogenen Daten nicht
            mehr einverstanden oder diese unrichtig geworden sein, werden wir
            auf eine entsprechende Weisung hin die Löschung oder Sperrung Ihrer
            Daten veranlassen oder die notwendigen Korrekturen vornehmen (soweit
            dies nach dem geltendem Recht möglich ist). Sie haben jederzeit das
            Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten
            Daten, deren Herkunft und Empfänger sowie den Zweck der Speicherung.
          </p>
          <p>
            Ihren Widerruf oder Fragen zum Thema Datenschutz in unserem
            Unternehmen sowie mögliche Auskunftsersuchen richten Sie bitte
            direkt an unseren externen Datenschutzbeauftragten: HEGO
            Informationstechnologie GmbH, Telegrafenstrasse 8 in 42929
            Wermelskirchen Telefon 02196 / 88 29 7-55, E-Mail: dsb@hego-it.com,
            Web:
            <a 
              href="https://www.hego-it.com" 
              target="_blank"
            >
              https://www.hego-it.com
            </a>. 
            Bitte geben Sie bei der Anfrage an, dass sich diese auf STARCAR
            bezieht, um Rückfragen zu vermeiden.
          </p>
          <h3>Rechte der betroffenen Personen</h3>
          <p>
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Datenverarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend Art. 15 DSGVO.
          </p>
          <p>
            Sie haben entsprechend. Art. 16 DSGVO das Recht, die
            Vervollständigung der Sie betreffen­den Daten oder die Berichtigung
            der Sie betreffen­den unrichtigen Daten zu verlangen.
          </p>
          <p>
            Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
            dass betreffende Daten unverzüglich gelöscht werden, bzw.
            alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der
            Verarbeitung der Daten zu verlangen.
          </p>
          <p>
            Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
            die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
            erhalten und deren Übermittlung an andere Verantwortliche zu
            fordern.
          </p>
          <p>
            Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
            der zuständigen Aufsichtsbehörde einzureichen.
          </p>
          <h3>Newsletter</h3>
          <p>
            Wenn Sie den auf der Webseite angebotenen Newsletter empfangen
            möchten, benötigen wir von Ihnen eine valide Email-Adresse. Die
            Angabe Ihres Namens ist optional und ist lediglich für eine korrekte
            Anrede notwendig (jedoch keine Pflicht!). Nach Eingabe Ihrer
            Email-Adresse auf unserer Webseite zur Anmeldung zum Newsletter
            erhalten Sie eine Email mit einem Aktivierungs-Link. Nur wenn Sie
            diesen Aktivierungs-Link anklicken, wird Ihre Email-Adresse aus der
            Anmeldung für den Newsletter-Bezug freigeschaltet, sog. double
            opt-in Verfahren. Sollten Sie die Aktivierung nicht durchführen,
            löschen wir den Eintrag Ihrer Mail-Adresse aus der Vorstufe nach
            spätestens 14 Tagen.
          </p>
          <p>
            Am Ende eines jeden versendeten Newsletters finden Sie einen Link,
            um sich aus dem Newsletter jederzeit wieder auszutragen. Ihr Eintrag
            wird dann automatisch gelöscht. Ihre Einwilligung zur Speicherung
            der Daten, der Email-Adresse sowie deren Nutzung zum Versand des
            Newsletters können Sie jederzeit widerrufen.
          </p>
          <h3>Kontaktformular / Email-Anfragen</h3>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen an uns übermittelt und gespeichert. Diese
            Daten geben wir nicht ohne Ihre Einwilligung weiter. Ihre Angaben
            werden ausschließlich zur Beantwortung Ihres Anliegens verarbeitet
            und genutzt. Sofern diese keiner gesetzlichen Aufbewahrungspflicht
            unterliegen, werden sie nach Abschluss Ihres Anliegens bei uns
            gelöscht. Dies gilt ebenfalls für Anfragen, die Sie uns per Email
            übersenden.
          </p>
          <h3>Bewerbungen</h3>
          <p>
            Über unsere Webseite schreiben wir gelegentlich zu besetzende
            Stellen aus. Gerne können Sie sich auf diese Stellenanzeigen bei uns
            bewerben. Bitte beachten Sie, dass der elektronische Versand per
            Mail unsicher ist. Eine E-Mail hat datenschutzrechtlich den
            Schutzwert einer Postkarte. Wenn Sie potentiellen Missbrauch auf dem
            Übertragungsweg ausschließen wollen, nutzen Sie entweder Briefpost
            oder schützen Ihre Anhänge mittels eines Passworts, das Sie uns
            separat mitteilen.
          </p>
          <p>
            Ihre personenbezogenen Daten werden im Rahmen des Bewerberverfahrens
            ausschließlich zur Auswahl geeigneter Kandidaten genutzt. Sobald die
            Position besetzt ist, werden Ihre Unterlagen nach Ablauf einer Frist
            zur Abwehr von Rechtsstreitigkeiten in unseren Systemen gelöscht
            (spätestens nach 6 Monaten). Eine werbliche Nutzung erfolgt explizit
            nicht. Sofern wir Ihre Bewerbung nicht berücksichtigen konnten,
            diese aber für zukünftige Stellenbesetzungen von Interesse sein
            könnte, werden wir Sie elektronisch um Einwilligung zur Aufnahme in
            unseren Stellenpool bitten.
          </p>
          <h3>Cookies</h3>
          <p>
            Unsere Internetseiten verwenden teilweise so genannte Cookies.
            Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
            keine Viren. Cookies dienen für uns ausschließlich dazu, unser
            Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
            Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt
            werden und die Ihr Browser speichert.
          </p>
          <p>
            Die meisten der von uns verwendeten Cookies sind so genannte
            „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <ul>
            <li>
              zid - Session ID der Applikation, um z.B. eine Reservierung in
              mehreren Schritten zu ermöglichen und hier den Benutzer wieder
              identifizieren zu können - Läuft ab wenn der Browser geschlossen
              wird
            </li>
            <li>
              voucher_code - Wird gesetzt wenn ein Rabattcode über das
              Navigieren zu einer bestimmten Seite verfügbar ist. Im
              Reservierungsprozess wird der Rabattcode dann automatisch
              verwendet und der Preis reduziert - Ablaufzeit 1 Tag
            </li>
            <li>
              estimatedPrice - Beinhaltet Details zur Reservierung wie den
              voraussichtlichen Mietpreis - Läuft ab, wenn der Browser
              geschlossen wird
            </li>
            <li>
              cookie_note_shown - Speichert ob der Hinweis "Diese Webseite
              verwendet Cookies, um Ihnen ein angenehmeres Surfen zu
              ermöglichen." bereits gesehen und bestätigt wurde. - Ablaufzeit
              365 Tage
            </li>
            <li>
              _ga - Google Analytics - Registriert eine eindeutige ID, die
              verwendet wird, um statistische Daten dazu, wie der Besucher die
              Website nutzt, zu generieren - Ablaufzeit 2 Jahre
            </li>
            <li>
              _gcl_au - Google Ads Conversion Tracking - Registriert eine
              eindeutige ID, die verwendet wird, um die Aktionen von Nutzern
              anzuzeigen, die die Website nach dem Anklicken einer Anzeige
              besuchen - Ablaufzeit 3 Monate
            </li>
            <li>
              _gcl_aw - Google Ads Conversion Tracking - Dieses Cookie wird
              gesetzt, wenn ein User über einen Klick auf eine Google
              Werbeanzeige auf die Website gelangt. Es enthält Informationen
              darüber, welche Werbeanzeige geklickt wurde, sodass erzielte
              Erfolge der Anzeige zugewiesen werden können - Ablaufzeit 3 Monate
            </li>
            <li>
              _gac_UA-# - Google Adwords - Dieses Cookie wird gesetzt, wenn ein
              User über einen Klick auf eine Google Werbeanzeige auf die Website
              gelangt. Es enthält Informationen darüber, welche Werbeanzeige
              geklickt wurde, sodass erzielte Erfolge der Anzeige zugewiesen
              werden können - Ablaufzeit 90 Tage
            </li>
            <li>
              _fbp - Facebook Pixel - Wird von Facebook genutzt, um eine Reihe
              von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter
              Werbetreibender - Ablaufzeit 3 Monate
            </li>
            <li>
              belboon_tracking_id - Wird gesetzt, wenn ein "belboon"-Parameter
              in der URL gesetzt wurde, z.B. durch einen Link auf einer externen
              Seite. Die Information wird mit einer Reservierung an Starcar
              übergeben und dient der Zuordnung der Reservierung zu einem
              Werbepartner - Ablaufzeit 90 Tage
            </li>
          </ul>
          <h3>Aktualisierung dieser Datenschutzerklärung</h3>
          <p>
            Soweit wir neue Produkte oder Dienstleistungen einführen,
            Internet-Verfahren ändern oder wenn sich die Internet- und
            EDV-Sicherheitstechnik weiterentwickelt, wird diese
            „Datenschutzerklärung“ aktualisiert. Wir behalten uns deshalb das
            Recht vor, die Erklärung nach Bedarf zu ändern oder zu ergänzen. Die
            Änderung werden wir an dieser Stelle veröffentlichen. Daher sollten
            Sie diese Website regelmäßig aufrufen, um sich über den aktuellen
            Stand der Datenschutzerklärung zu informieren.
          </p>
          <h3>SSL-Verschlüsselung</h3>
          <p>
            Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
            die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung.
            Eine verschlüsselte Verbindung erkennen Sie daran, dass die
            Adresszeile des Browsers von „http://“&nbsp;auf
            „https://“&nbsp;wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h3>Haftung</h3>
          <p>
            Alle auf dieser Website enthaltenen Informationen wurden mit großer
            Sorgfalt geprüft. Wir übernehmen jedoch keine Gewähr dafür, dass die
            Inhalte unserer eigenen Webseiten jederzeit korrekt, vollständig und
            auf dem neuesten Stand sind.
          </p>
          <h3>Datensicherheit</h3>
          <p>
            Allgemein gilt das Internet als unsicheres Medium. Im Vergleich z.B.
            zur Telefonleitung kann eine Übermittlung von Daten im Internet
            durch unberechtigte Dritte leichter abgehört, aufgezeichnet oder
            sogar verändert werden.
          </p>
          <h3>Weitere Informationen</h3>
          <p>
            Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit
            Rede und Antwort bezüglich der Verarbeitung Ihrer personenbezogenen
            Daten stehen. Wenn Sie Fragen haben, die Ihnen diese
            Datenschutzerklärung nicht beantworten konnte oder wenn Sie zu einem
            Punkt vertiefte Informationen wünschen, wenden Sie sich bitte
            jederzeit an unseren Datenschutzbeauftragten.
          </p>
          <h3>Schlussbemerkung</h3>
          <p>
            Wir gewährleisten die Vertraulichkeit und Sicherheit Ihrer
            personenbezogenen Daten dadurch, dass
          </p>
          <ul>
            <li>
              wir Ihre persönlichen Daten, soweit Sie uns diese über unsere
              Website oder im Rahmen der E Mail-Kommunikation mitteilen, nur zur
              Erfüllung Ihres Wunschs oder Anliegens verwenden,
            </li>
            <li>
              unsere Mitarbeiterinnen und Mitarbeiter Verschwiegenheitspflichten
              zu wahren haben,
            </li>
            <li>
              unsere Sicherheitsvorkehrungen in angemessenem Umfang dem
              aktuellen Stand der Technik entsprechen,
            </li>
            <li>
              unsere Systeme regelmäßig auf Sicherheit überprüft werden, damit
              wir nachhaltig vor etwaigen Schädigungen, Verlusten und Zugriffen
              auf bei uns vorgehaltene Daten schützen können,
            </li>
            <li>
              und für die Beachtung der „Datenschutzerklärung“ unser
              Datenschutzbeauftragter sorgt.
            </li>
          </ul>
        </div>
      </dialog>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { FormModule } from '@/composables/use-form';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Textarea from './Textarea.vue';
import Input from './Input.vue';
import Switch from './Switch.vue';
import Dropdown from './Dropdown.vue';
import Button from './Button.vue';
import { IOption, IStepsSelected } from '@/types';
import { TranslationHandler } from '@/mixins/translation-handler';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true
  });
  formModule = prop({
    type: Object as () => FormModule,
    required: true
  });
}

@Options({
  components: {
    Input,
    Switch,
    Dropdown,
    Textarea,
    Button
  }
})
export default class Contact extends mixins(
  TranslationHandler,
  Vue.props(Props)
) {
  isLoading = false;

  get salutations(): IOption[] {
    return [
      {
        id: '',
        text: '',
        isSelected: this.form.salutation === '',
      },
      {
        id: 'Herr',
        text: this.$t(['form', 'salutationMan']),
        isSelected: this.form.salutation === 'Herr'
      },
      {
        id: 'Frau',
        text: this.$t(['form', 'salutationWoman']),
        isSelected: this.form.salutation === 'Frau'
      },
      {
        id: 'keine Angabe',
        text: this.$t(['form', 'salutationNone']),
        isSelected: this.form.salutation === 'keine Angabe',
      }
    ];
  }

  isErrorShown: boolean = false;
  root: HTMLElement | null = null;

  $refs!: {
    dialog: any;
  };

  mounted() {
    this.root = document.querySelector('body > .root');

    const openDialog: any = document.querySelector('.js-privacy-policy-open');
    openDialog.addEventListener('click', this.openDialog);

    this.$refs.dialog.addEventListener('close', this.closeDialog);
  }

  openDialog() {
    this.$refs.dialog.showModal();

    const scrollY = window.scrollY;
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;

    this.root?.setAttribute('inert', '');
  }

  closeDialog() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.root?.removeAttribute('inert');
  }

  form: { [k: string]: string | boolean } = {
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
    mailback: false,
    callback: false,
    legalities: false
  };

  formRequired: string[] = [
    'firstName',
    'lastName',
    'phone',
    'email',
    'message',
    'legalities'
  ];

  formEmail: string[] = ['email'];
  formPhone: string[] = ['phone'];

  getIsFieldError(type: string, val: string): boolean {
    const isRequiredError =
      this.formRequired.includes(type) && !this.getIsValidRequired(val);
    const isEmailError =
      this.formEmail.includes(type) && !this.getIsValidEmail(val);
    const isPhoneError = this.formPhone.includes(type) && !this.getIsValidPhone(val);

    return this.isErrorShown && (isRequiredError || isEmailError || isPhoneError);
  }

  getIsValidRequired(val: string | boolean): boolean {
    return val !== '' && val !== false;
  }

  getIsValidEmail(val: string): boolean {
    return /\S+@\S+\.\S+/.test(val) && !/\s/g.test(val);
  }

  getIsValidPhone(val: string): boolean {
    return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(val);
  }

  get formIsValid(): boolean {
    const isRequired = !this.formRequired.some(
      entry => !this.getIsValidRequired(this.form[entry])
    );
    const isEmail = !this.formEmail.some(
      entry => !this.getIsValidEmail(this.form[entry] as string)
    );
    const isPhone = !this.formPhone.some(
      entry => !this.getIsValidPhone(this.form[entry] as string)
    );

    return isRequired && isEmail && isPhone;
  }

  created() {
    this.form.message = this.$t(['form', 'messageDefault']);
  }

  setFormValue(key: string, value: string | boolean) {
    this.form[key] = value;
  }

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {};

    Object.keys(this.stepsModule.state.selected).forEach(
      key =>
        (selected[key] = this.stepsModule.state.selected[key].map(
          selectedKey => {
            const value = this.$t([key, 'options', selectedKey]);

            return value.replace(/<\/?[^>]+(>|$)/g, '');
          }
        ))
    );

    return selected;
  }

  async submit() {
    this.isErrorShown = false;
    this.isLoading = true;

    if (this.formIsValid === false) {
      this.isLoading = false;
      this.isErrorShown = true;
      return;
    }

    const isSuccess = await this.formModule.send(
      this.form,
      this.selectedTranslated
    );

    if (isSuccess === true) {
      this.isLoading = false;
      this.stepsModule.next();
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.contact {
  .contact__inner {
    position: relative;

    @media only screen and (min-width: 960px) {
      margin-block-start: 60px;
    }
  }

  .contact__loader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    inset: -0.5rem;
    justify-content: center;
    position: absolute;

    > svg {
      align-self: center;
      aspect-ratio: 1 / 1;
      block-size: 3rem;
      inline-size: 3rem;
      stroke: $black;
    }
  }

  .contact__inputs {
    @media only screen and (min-width: 960px) {
      display: grid;
      gap: $spacing-1 $spacing-1;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        ". ."
        ". .";
      margin-block-end: 20px;

      &:first-child::before {
        content: "";
        display: block;
        grid-column: 2 / -1;
        grid-row: 1;
      }
    }

    .input,
    .dropdown-container {
      margin-bottom: 20px;

      @media only screen and (min-width: 960px) {
        margin: 0;
      }
    }
  }

  :deep(.btn-privacy-policy) {
    all: unset;
    cursor: pointer;
    font: inherit;
    text-decoration: underline;
  }

  .button {
    margin: 0 auto;
  }

  .switch {
    margin-bottom: 30px;
  }

  .textarea {
    margin-bottom: 30px;
  }
}

.dialog {
  background-color: $white;
  block-size: calc(100vh - 8rem);
  border: none;
  box-sizing: border-box;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  inline-size: calc(100vw - 8rem);
  margin: 4rem 0 0 4rem;
  padding: 0;
  position: relative;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.dialog__inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  inset: 0;
  overflow-y: scroll;
  padding: 3rem;
  position: absolute;
}

.dialog__inner > * {
  margin-block: 0;
}

.dialog__inner > h2 + h3 {
  margin-block-start: 2rem;
}

.dialog__inner > p + h3 {
  margin-block-start: 1rem;
}

.dialog__logo {
  block-size: 3rem;
}

.dialog__title {
  max-inline-size: 65rem;
}

.dialog__title > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}

.dialog__qr-code {
  aspect-ratio: 1 / 1;
  block-size: 10rem;
  margin-block-start: 2rem;

  @media only screen and (min-width: 960px) {
    block-size: 15rem;
    margin-block-start: 0;
  }
}

.dialog__qr-code > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}

.dialog__close {
  all: unset;
  align-items: center;
  aspect-ratio: 1 / 1;
  border: 1px solid $black;
  border-radius: 9999px;
  block-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 10;
}

.dialog__close > svg {
  block-size: 1rem;
}
</style>
