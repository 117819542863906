<template>
  <div class="input" :class="classNames">
    <input :type="type" v-model="text" />
    <label>{{ placeholder }}</label>
  </div>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  placeholder = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: String,
    required: true,
  })

  type = prop({
    type: String,
    default: 'text',
  })

  error = prop({
    type: Boolean,
    default: false,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    text(text: string) {
      this.$emit('on-change', text)
    }
  }
})
export default class Input extends Vue.props(Props) {
  text: string = ''

  created() {
    this.text = this.value
  }

  get classNames() {
    return [
      { 'input--active': (this.text.length > 0) },
      { 'input--error': this.error },
    ]
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.input {
  position: relative;
  border: 2px solid $gray-1;
  align-items: center;
  height: 48px;
  cursor: pointer;

  @media only screen and (min-width: 960px) {
    &:hover {
      border: 2px solid $gray-2;
    }
  }

  input {
    transition: all 0.2s linear;
    font-size: 14px;
    color: $black;
    outline: none;
    border: 0;
    box-sizing: border-box;
    padding: 18px 20px 0 20px;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-family: Lavazza, Helvetica, Arial, sans-serif;

    &:focus ~ label,
    &:active ~ label {
      top: 8px;
      transform: translateY(0);
      font-size: 12px;
    }
  }

  label {
    pointer-events: none;
    font-size: 14px;
    font-weight: 500;
    color: $gray-2;
    position: absolute;
    left: 20px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
  }

  &--active {
    label {
      top: 8px;
      transform: translateY(0);
      font-size: 12px;
    }
  }

  &--error.input--error {
    border-color: $red;
  }
}
</style>
