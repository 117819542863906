
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IOption, IStepSelectRegion } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Input from './Input.vue'
import Dropdown from './Dropdown.vue'
import { FormModule } from '@/composables/use-form';

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })

  formModule = prop({
    type: Object as () => FormModule,
    required: true,
  })
}

@Options({
  components: {
    Dropdown,
    Input,
  },
})
export default class DropdownSelect extends mixins(Vue.props(Props), TranslationHandler) {
  postcode: string = ''

  get placeholder(): string {
    return this.$t([this.stepsModule.state.stepCurrent.type, 'placeholder'])
  }
  
  get selectCurrent(): string[] | undefined {
    return this.stepsModule.state.selected[this.stepsModule.state.stepCurrent.type]
  }

  get isPostalshown(): boolean {
    return !!this.selectCurrent && this.selectCurrent[0] === IStepSelectRegion.DE
  }

  get isPostcodeError(): boolean {
    return this.postcode.length !== 5 && this.postcode !== ''
  }

  setPostcode(postcode: string) {
    this.postcode = postcode

    this.formModule.setContactDetails({ postcode: this.postcode })
  }
}
