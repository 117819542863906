<template>
  <div class="home">
    <div class="home__mood">
      <div class="home__mood__copy">
        <h2 v-html="textMap.headline"></h2>
        <h3 v-if="textMap.subline">
          {{ textMap.subline }}
        </h3>
        <ul>
          <li v-for="(item, index) in textMap.list" :key="index">
            <Icon class="home__mood__copy__icon" :keys="listItemIconKeys" />
            {{ item }}
          </li>
        </ul>
      </div>
      <Icon
        :keys="overlayMobileKeys"
        class="home__mood__copy__svg svg--mobile"
      />
      <img
        :src="stepsModule.state.imageMap['homeLeft']"
        alt=""
        class="home__mood__copy__image"
      />
    </div>
    <div class="home__select">
      <p class="home__select__headline">
        {{ $t([stepsModule.state.stepCurrent.type, "headline"]) }}
      </p>
      <span class="home__select__hint">
        {{ $t(["general", "multipleChoice"]) }}
      </span>

      <!-- Tiles -->
      <slot></slot>
      <!-- Tiles end -->

      <Button
        class="home__button"
        @on-click="stepsModule.next"
        :text="$t(['general', 'buttonNext'])"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
      />

      <p class="home__select__skip-steps">
        {{ $t(["general", "contact"]) }}
        <span @click="stepsModule.nextContact">{{
          $t(["general", "contactClick"])
        }}</span>
      </p>
    </div>
    <Icon :keys="overlayDesktopKeys" class="home__svg svg--desktop" />
    <img
      :src="stepsModule.state.imageMap['homeLeft']"
      alt=""
      class="home__image image--desktop"
    />
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { IMap, IStepLandingIconKeys, IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler'
import Button from './Button.vue'
import Icon from './Icon.vue'


class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button, Icon
  }
})
export default class StepSubpageHome extends mixins(Vue.props(Props), TranslationHandler) {
  listItemIconKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.LIST_ARROW)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.LIST_ARROW];
  overlayMobileKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.MOBILE)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.MOBILE];
  overlayDesktopKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.DESKTOP)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.DESKTOP];

  get textMap(): IMap {
    return this.stepsModule.state.textMap
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

@mixin apply-gradient {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #002856, #110000);
  top: 0;
  left: 0;
  opacity: 0.6;
  position: absolute;
}

@mixin svg-styles {
  position: absolute;
  z-index: 2;
  // svg doesn't fill viewport so must overflow
  width: 102vw;
  left: -5px;
  right: 0;

  &.svg--mobile {
    bottom: -5px;
    display: none;
    @media only screen and (max-width: 1199px) {
      display: block;
    }
  }
  &.svg--desktop {
    bottom: -5%;
    display: none;
    @media only screen and (min-width: 1200px) {
      display: block;
    }
  }
}

@mixin image-styles {
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  object-position: 0 10%;
  height: 100%;
  @media only screen and (min-width: 1200px) {
    object-position: 50% 50%;
    height: -webkit-fill-available;
  }
  @media only screen and (max-width: 1199px) {
    &.image--desktop {
      display: none;
    }
  }
}

.home {
  overflow: hidden;
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    min-height: 800px;
  }

  > div {
    @media only screen and (min-width: 1200px) {
      flex: 1;
    }
  }

  &__image {
    @include image-styles;
  }

  &__svg {
    @include svg-styles;
    width: 100vw;
    left: 0;
  }

  &__mood {
    display: flex;
    flex: 1;
    // overflow: hidden;
    color: $white;
    font-size: 32px;
    line-height: 0;
    position: relative;

    &__copy {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 40px 20px;
      justify-content: center;
      line-height: initial;
      z-index: 1;
      max-width: 770px;
      margin: auto;

      &__image {
        @include image-styles;
      }

      &__svg {
        @include svg-styles;
      }

      h2 {
        font-weight: 600;
        @media only screen and (min-width: 1200px) {
          font-size: 3.5vw;
        }
        @media only screen and (min-width: 1920px) {
          font-size: 67.5px;
        }
      }
      h3 {
        font-weight: 400;
      }
      h2,
      h3 {
        margin-bottom: 40px;
      }
      @media only screen and (max-width: 1199px) {
        padding: 40px 20px 20% 20px;
      }
      li {
        display: flex;
        align-items: center;
        font-size: 20px;
        margin: 20px 0;
        font-weight: bold;

        .home__mood__copy__icon {
          display: flex;
          margin-bottom: auto;
          margin-right: 10px;
        }
      }
    }

    @media only screen and (max-width: 1199px) {
      &::after {
        @include apply-gradient;
      }
    }
  }

  &__button {
    margin: 30px 0;
  }

  &__select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 28px 22px;
    z-index: 1;

    img {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 400px;
    }

    &__headline {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      line-height: 32px;
      margin-bottom: 20px;
      max-width: 530px;
      padding: 0 20px;
      color: $blue-1;
      @media only screen and (min-width: 1200px) {
        color: $white;
      }
    }
    &__hint {
      font-size: 14px;
      color: $gray-2;
      margin-bottom: $spacing-3;
      @media only screen and (min-width: 1200px) {
        color: $white;
      }
    }

    &__skip-steps {
      font-size: 14px;
      color: $gray-2;
      font-weight: 400;
      text-align: center;

      span {
        text-decoration: none;
        border-bottom: 1px solid $gray-2;
        color: inherit;
        cursor: pointer;
      }
      @media only screen and (min-width: 1200px) {
        color: $white;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    &::after {
      @include apply-gradient;
    }
  }
}
</style>
