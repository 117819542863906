
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStepSelectElectricity, IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Button from './Button.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  emits: ['on-close'],
  components: {
    Button,
  },
})
export default class Overlay extends mixins(Vue.props(Props), TranslationHandler) {
  get isNextInstant(): boolean {
    // Because of the very sepecific functionality this logic is defined here
    return this.stepsModule.state.stepCurrent.type === IStepType.ELECTRICITY
  }

  get title(): string {
    // Because of the very sepecific functionality this logic is defined here
    const electricitySelected = (this.stepsModule.state.selected[IStepType.ELECTRICITY] as IStepSelectElectricity[]) || []

    if (electricitySelected.includes(IStepSelectElectricity.WATER)) {
      return this.$t([this.stepsModule.state.stepCurrent.type, 'overlayTitle2'])
    }

    return this.$t([this.stepsModule.state.stepCurrent.type, 'overlayTitle'])
  }

  onButton() {
    if (this.isNextInstant === true) {
      this.stepsModule.next()
    }
    this.$emit('on-close')
  }
}
