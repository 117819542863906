<template>
  <button
    class="button"
    type="button"
    :class="{
      'is-secondary': secondary,
      'is-tertiary': tertiary,
      'is-big': big
    }"
    :disabled="disabled"
    @click="$emit('on-click')"
  >
    <span class="button__title" v-html="text"></span>
    <svg
      class="button__icon"
      v-if="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
    >
      <path
        d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
      />
    </svg>
  </button>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  disabled = prop({
    type: Boolean,
    default: false
  });
  secondary = prop({
    type: Boolean
  });
  tertiary = prop({
    type: Boolean
  });
  big = prop({
    type: Boolean
  });
  icon = prop({
    type: Boolean
  });
  text = prop({
    type: String
  });
}

@Options({
  emits: ['on-click']
})
export default class Button extends Vue.props(Props) {}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.button {
  align-items: center;
  background: $yellow-2;
  border: 0;
  color: $black;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  gap: 0.5em;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 1rem 2rem;
  position: relative;
  transition: background-color 0.25s ease-out;
  text-transform: uppercase;

  &:disabled,
  &[disabled] {
    background: $gray-1;
    color: $white;
    cursor: not-allowed;

    > .button__icon {
      fill: $white;
    }

    &:hover {
      background: $gray-1;
    }
  }

  @media only screen and (min-width: 960px) {
    &:hover {
      background: $black;
      color: $white;

      > .button__icon {
        fill: $white;
      }
    }
  }
}

.button__title {
  white-space: nowrap;
}

.button__icon {
  block-size: 1em;
  fill: $black;
  transition: fill 0.25s ease-out;
}

.is-secondary {
  background: $white;
  border: 2px solid $black;
  color: $black;

  @media only screen and (min-width: 960px) {
    &:hover {
      background: $black;
      color: $white;
    }
  }
}

.is-tertiary {
  background: $black;
  border: 2px solid $black;
  color: $white;

  > .button__icon {
    fill: $white;
  }

  @media only screen and (min-width: 960px) {
    &:hover {
      background: $white;
      color: $black;

      > .button__icon {
        fill: $black;
      }
    }
  }
}

.is-big {
  font-size: clamp(1rem, 2.5vw, 2rem);
}
</style>
