
import { StepsModule } from '@/composables/use-steps';
import { FormModule } from '@/composables/use-form';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Textarea from './Textarea.vue';
import Input from './Input.vue';
import Switch from './Switch.vue';
import Dropdown from './Dropdown.vue';
import Button from './Button.vue';
import { IOption, IStepsSelected } from '@/types';
import { TranslationHandler } from '@/mixins/translation-handler';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true
  });
  formModule = prop({
    type: Object as () => FormModule,
    required: true
  });
}

@Options({
  components: {
    Input,
    Switch,
    Dropdown,
    Textarea,
    Button
  }
})
export default class Contact extends mixins(
  TranslationHandler,
  Vue.props(Props)
) {
  isLoading = false;

  get salutations(): IOption[] {
    return [
      {
        id: '',
        text: '',
        isSelected: this.form.salutation === '',
      },
      {
        id: 'Herr',
        text: this.$t(['form', 'salutationMan']),
        isSelected: this.form.salutation === 'Herr'
      },
      {
        id: 'Frau',
        text: this.$t(['form', 'salutationWoman']),
        isSelected: this.form.salutation === 'Frau'
      },
      {
        id: 'keine Angabe',
        text: this.$t(['form', 'salutationNone']),
        isSelected: this.form.salutation === 'keine Angabe',
      }
    ];
  }

  isErrorShown: boolean = false;
  root: HTMLElement | null = null;

  $refs!: {
    dialog: any;
  };

  mounted() {
    this.root = document.querySelector('body > .root');

    const openDialog: any = document.querySelector('.js-privacy-policy-open');
    openDialog.addEventListener('click', this.openDialog);

    this.$refs.dialog.addEventListener('close', this.closeDialog);
  }

  openDialog() {
    this.$refs.dialog.showModal();

    const scrollY = window.scrollY;
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;

    this.root?.setAttribute('inert', '');
  }

  closeDialog() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.root?.removeAttribute('inert');
  }

  form: { [k: string]: string | boolean } = {
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
    mailback: false,
    callback: false,
    legalities: false
  };

  formRequired: string[] = [
    'firstName',
    'lastName',
    'phone',
    'email',
    'message',
    'legalities'
  ];

  formEmail: string[] = ['email'];
  formPhone: string[] = ['phone'];

  getIsFieldError(type: string, val: string): boolean {
    const isRequiredError =
      this.formRequired.includes(type) && !this.getIsValidRequired(val);
    const isEmailError =
      this.formEmail.includes(type) && !this.getIsValidEmail(val);
    const isPhoneError = this.formPhone.includes(type) && !this.getIsValidPhone(val);

    return this.isErrorShown && (isRequiredError || isEmailError || isPhoneError);
  }

  getIsValidRequired(val: string | boolean): boolean {
    return val !== '' && val !== false;
  }

  getIsValidEmail(val: string): boolean {
    return /\S+@\S+\.\S+/.test(val) && !/\s/g.test(val);
  }

  getIsValidPhone(val: string): boolean {
    return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(val);
  }

  get formIsValid(): boolean {
    const isRequired = !this.formRequired.some(
      entry => !this.getIsValidRequired(this.form[entry])
    );
    const isEmail = !this.formEmail.some(
      entry => !this.getIsValidEmail(this.form[entry] as string)
    );
    const isPhone = !this.formPhone.some(
      entry => !this.getIsValidPhone(this.form[entry] as string)
    );

    return isRequired && isEmail && isPhone;
  }

  created() {
    this.form.message = this.$t(['form', 'messageDefault']);
  }

  setFormValue(key: string, value: string | boolean) {
    this.form[key] = value;
  }

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {};

    Object.keys(this.stepsModule.state.selected).forEach(
      key =>
        (selected[key] = this.stepsModule.state.selected[key].map(
          selectedKey => {
            const value = this.$t([key, 'options', selectedKey]);

            return value.replace(/<\/?[^>]+(>|$)/g, '');
          }
        ))
    );

    return selected;
  }

  async submit() {
    this.isErrorShown = false;
    this.isLoading = true;

    if (this.formIsValid === false) {
      this.isLoading = false;
      this.isErrorShown = true;
      return;
    }

    const isSuccess = await this.formModule.send(
      this.form,
      this.selectedTranslated
    );

    if (isSuccess === true) {
      this.isLoading = false;
      this.stepsModule.next();
      return;
    }
  }
}
