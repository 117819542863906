
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  placeholder = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: String,
    required: true,
  })

  error = prop({
    type: Boolean,
    default: false,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    text(text: string) {
      this.$emit('on-change', text)
    }
  }
})
export default class Textarea extends Vue.props(Props) {
  text: string = ''

  created() {
    this.text = this.value
  }
}
