
import { IOption } from '@/types';
import { Options, prop, Vue } from 'vue-class-component';
import Checkbox from './Checkbox.vue';

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  isAlternative = prop({
    type: Boolean,
  })

  placeholder = prop({
    type: String,
    required: true,
  })

  isCheckbox = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['select'],
  components: {
    Checkbox
  },
})
export default class Dropdown extends Vue.props(Props) {
  isOpen = false

  $refs!: {
    dropdown: HTMLElement,
  }

  get isSelectedOptions(): IOption[] {
    return this.options.filter((option) => option.isSelected)
  }

  get isSelectedOptionString(): string {
    return this.isSelectedOptions.map((o) => o.text).join(', ')
  }

  mounted() {
    document.addEventListener('click', this.bindClick)
  }

  unmounted() {
    document.removeEventListener('click', this.bindClick)
  }

  bindClick(event: Event) {
    const $tooltip: HTMLElement | undefined = this.$refs.dropdown

    if ($tooltip === undefined || this.isOpen === false) { return }

    if ($tooltip.contains((event.target as HTMLElement)) === false) {
      this.isOpen = false
    }
  }

  onOption(option: IOption) {
    if (this.isCheckbox === false) {
      this.isOpen = false
    }
    this.$emit('select', option.id)
  }
}
