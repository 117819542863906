
import { STEP_TILES_LABEL } from '@/config/steps';
import { Options, Vue, prop } from 'vue-class-component';
import Icon from './Icon.vue'

class Props {
  isSelected = prop({
    type: Boolean,
    required: true,
  })
  id = prop({
    type: String,
    required: true,
  })
  text = prop({
    type: String,
    required: true,
  })
  iconKeys = prop({
    type: Object as () => string[],
    required: false
  })
}

@Options({
  emits: ['on-click'],
  components: {
    Icon,
  },
})
export default class Tile extends Vue.props(Props) {
  get iconFill() {
    return this.isSelected === false ? '#000' : '#fff'
  }

  get hasLabel() {
    return STEP_TILES_LABEL.includes(this.id)
  }
}
