import { createApp } from 'vue'
import App from './App.vue'

const $elements = document.getElementsByClassName('root');

for (let i = 0; i < $elements.length; i++) {
  createApp({
    components: {
      App,
    }
  }).mount($elements[i])
}
