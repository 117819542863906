
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  text = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    isOpen(isOpen: string) {
      this.$emit('on-change', isOpen)
    }
  }
})
export default class Switch extends Vue.props(Props) {
  isOpen: boolean = false

  created() {
    this.isOpen = this.value
  }
}
