
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler'
import Button from './Button.vue'
import Icon from '@/components/Icon.vue';
import { IStepFooterHomeIconKeys, IStepType } from '@/types';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button, Icon
  }
})
export default class StepPrefooterHome extends mixins(Vue.props(Props), TranslationHandler) {
  overlayKeys: Array<(IStepType.FOOTER_HOME | IStepFooterHomeIconKeys.OVERLAY)> = [IStepType.FOOTER_HOME, IStepFooterHomeIconKeys.OVERLAY];
}
