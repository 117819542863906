<template>
  <div class="result">
    <div class="result__header">
      <svg
        title="Starcar"
        aria-labelledby="svg-logo"
        role="img"
        class="result__logo"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 280 48"
      >
        <title id="svg-logo">Starcar</title>
        <path
          d="M15.08 47.63c5.696 0 15.547-1.576 17.41-12.25 1.54-8.598-1.792-13.505-10.675-15.87-3.725-.966-9.314-2.435-8.561-6.196.465-2.221 2.507-3.546 5.373-3.546 2.579 0 5.48 1.074 7.737 2.865.358-.824 1.003-2.041 1.648-3.331L30.662 4A33.529 33.529 0 0 0 17.123.597C16.004.6 14.89.707 13.791.92a14.078 14.078 0 0 0-12.18 13.97c-.357 6.34 2.222 9.958 8.598 12.072l2.436.716a81.166 81.166 0 0 1 5.445 1.827c1.468.573 2.614 1.863 2.937 3.295.286 1.326-.108 2.651-1.146 3.762a6.448 6.448 0 0 1-4.872 1.898c-3.224 0-7.057-1.648-10.316-4.477L3.26 36.705 0 42.759c1.433 1.003 7.307 4.8 14.615 4.907h.466v-.036ZM65.983 1.636H35.391l.036 9.385h9.886l.036 35.176h11.069V10.95h9.564V1.636Zm28.442-.143H82.137L65.015 46.197h11.857l3.009-7.88H96.5l2.974 7.88h11.785L94.424 1.493ZM83.57 29.577l4.514-13.648 4.513 13.612H83.57v.035ZM220.55 1.17H207.94l-17.48 45.062h12.107l3.152-8.131h16.657l3.045 8.095h11.821L220.549 1.171ZM209.66 29.183l4.513-13.397 4.514 13.432h-9.027v-.035Zm-23.069 15.976V32.156a14.259 14.259 0 0 1-10.245 4.12c-3.188 0-6.161-1.255-8.418-3.547a13.258 13.258 0 0 1-3.582-8.884c0-6.376 3.761-12.895 11.785-12.895a13.609 13.609 0 0 1 10.711 4.656V1.994a31.775 31.775 0 0 0-12.323-1.97c-5.659.287-21.85 5.266-21.994 23.57a21.852 21.852 0 0 0 6.269 16.156 26.866 26.866 0 0 0 17.266 7.88c5.48 0 9.277-1.79 10.531-2.507v.036Zm77.875-17.481 1.218-.143c.358-.036 9.134-1.791 9.134-13.146 0-12.144-11.391-13.003-13.612-13.04l-19.164-.035v44.92h11.212v-18.27l11.498 18.27h14.042l-14.257-18.556h-.071Zm-10.675-6.663V10.376s8.884-1.325 8.884 5.517c0 6.34-8.884 5.122-8.884 5.122ZM151.988 46.197l-13.755-18.34.824-.143c.358-.072 9.313-1.791 9.17-13.146-.215-12.18-11.427-13.003-13.648-13.04h-19.164l-.036 44.67h11.319v-18.09l11.284 18.09h14.006Zm-24.86-25.003V10.557s8.956-1.326 8.92 5.48c0 6.376-8.92 5.159-8.92 5.159Z"
        />
      </svg>
      <h2 class="result__headline">Das Ergebnis</h2>
    </div>
    <div class="result__wrapper">
      <svg
        v-if="isLoading"
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 38 38"
        class="result__loader"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
            </path>
          </g>
        </g>
      </svg>
      <ul v-else class="result__winners">
        <li class="result__winner">
          <div class="result__visual">
            <img src="/result-car-02.png" alt="" width="900" height="196" />
          </div>
          <div class="result__info">
            <h3>{{ results[1].title }}</h3>
            <p>{{ results[1].location }}</p>
          </div>
        </li>
        <li class="result__winner">
          <div class="result__visual">
            <img src="/result-car-01.png" alt="" width="1280" height="196" />
          </div>
          <div class="result__info">
            <h3>{{ results[0].title }}</h3>
            <p>{{ results[0].location }}</p>
          </div>
        </li>
        <li class="result__winner">
          <div class="result__visual">
            <img src="/result-car-03.png" alt="" width="760" height="196" />
          </div>
          <div class="result__info">
            <h3>{{ results[2].title }}</h3>
            <p>{{ results[2].location }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="result__nav">
      <Button
        @on-click="stepsModule.next"
        :text="$t([stepsModule.state.stepCurrent.type, 'contactdata'])"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
      />
      <Button
        @on-click="openDialog"
        :secondary="true"
        :text="$t(['general', 'buttonJobs'])"
      />
      <Button
        @on-click="stepsModule.back"
        :secondary="true"
        :text="$t(['general', 'buttonBack'])"
        :disabled="!stepsModule.state.isBackAllowed"
      />
    </div>
    <div class="result__close" @click="stepsModule.backHome">
      <svg
        title="Schließen"
        aria-labelledby="svg-close"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <title id="svg-close">Schließen</title>
        <path
          d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
        />
      </svg>
    </div>
  </div>
  <Teleport to="body">
    <dialog ref="dialog" class="dialog">
      <div class="dialog__inner">
        <svg
          title="Starcar"
          aria-labelledby="svg-logo"
          role="img"
          class="dialog__logo"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 280 48"
        >
          <title id="svg-logo">Starcar</title>
          <path
            d="M15.08 47.63c5.696 0 15.547-1.576 17.41-12.25 1.54-8.598-1.792-13.505-10.675-15.87-3.725-.966-9.314-2.435-8.561-6.196.465-2.221 2.507-3.546 5.373-3.546 2.579 0 5.48 1.074 7.737 2.865.358-.824 1.003-2.041 1.648-3.331L30.662 4A33.529 33.529 0 0 0 17.123.597C16.004.6 14.89.707 13.791.92a14.078 14.078 0 0 0-12.18 13.97c-.357 6.34 2.222 9.958 8.598 12.072l2.436.716a81.166 81.166 0 0 1 5.445 1.827c1.468.573 2.614 1.863 2.937 3.295.286 1.326-.108 2.651-1.146 3.762a6.448 6.448 0 0 1-4.872 1.898c-3.224 0-7.057-1.648-10.316-4.477L3.26 36.705 0 42.759c1.433 1.003 7.307 4.8 14.615 4.907h.466v-.036ZM65.983 1.636H35.391l.036 9.385h9.886l.036 35.176h11.069V10.95h9.564V1.636Zm28.442-.143H82.137L65.015 46.197h11.857l3.009-7.88H96.5l2.974 7.88h11.785L94.424 1.493ZM83.57 29.577l4.514-13.648 4.513 13.612H83.57v.035ZM220.55 1.17H207.94l-17.48 45.062h12.107l3.152-8.131h16.657l3.045 8.095h11.821L220.549 1.171ZM209.66 29.183l4.513-13.397 4.514 13.432h-9.027v-.035Zm-23.069 15.976V32.156a14.259 14.259 0 0 1-10.245 4.12c-3.188 0-6.161-1.255-8.418-3.547a13.258 13.258 0 0 1-3.582-8.884c0-6.376 3.761-12.895 11.785-12.895a13.609 13.609 0 0 1 10.711 4.656V1.994a31.775 31.775 0 0 0-12.323-1.97c-5.659.287-21.85 5.266-21.994 23.57a21.852 21.852 0 0 0 6.269 16.156 26.866 26.866 0 0 0 17.266 7.88c5.48 0 9.277-1.79 10.531-2.507v.036Zm77.875-17.481 1.218-.143c.358-.036 9.134-1.791 9.134-13.146 0-12.144-11.391-13.003-13.612-13.04l-19.164-.035v44.92h11.212v-18.27l11.498 18.27h14.042l-14.257-18.556h-.071Zm-10.675-6.663V10.376s8.884-1.325 8.884 5.517c0 6.34-8.884 5.122-8.884 5.122ZM151.988 46.197l-13.755-18.34.824-.143c.358-.072 9.313-1.791 9.17-13.146-.215-12.18-11.427-13.003-13.648-13.04h-19.164l-.036 44.67h11.319v-18.09l11.284 18.09h14.006Zm-24.86-25.003V10.557s8.956-1.326 8.92 5.48c0 6.376-8.92 5.159-8.92 5.159Z"
          />
        </svg>
        <div class="dialog__title">
          <img
            src="/result-dialog-title.png"
            alt="Hier finden Sie weitere Jobangebote"
            width="1054"
            height="247"
          />
        </div>
        <div class="dialog__qr-code">
          <img
            src="/result-dialog-qrcode.svg"
            alt=""
            width="279"
            height="279"
          />
        </div>
        <button
          @click="$refs.dialog.close()"
          type="reset"
          class="dialog__close"
        >
          <svg
            title="Schließen"
            aria-labelledby="svg-close"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <title id="svg-close">Schließen</title>
            <path
              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
            />
          </svg>
        </button>
      </div>
    </dialog>
  </Teleport>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStepsSelected } from '@/types';
import Button from './Button.vue';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true
  });
}

@Options({
  components: {
    Button
  }
})
export default class StepResult extends mixins(
  Vue.props(Props),
  TranslationHandler
) {
  results = [
    {
      title: 'Beruf',
      location: 'Standort',
    },
    {
      title: 'Beruf',
      location: 'Standort',
    },
    {
      title: 'Beruf',
      location: 'Standort',
    },
  ];

  isLoading = true;

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {};

    Object.keys(this.stepsModule.state.selected).forEach(
      key =>
        (selected[key] = this.stepsModule.state.selected[key].map(
          selectedKey => {
            const value = this.$t([key, 'options', selectedKey]);

            return value.replace(/<\/?[^>]+(>|$)/g, '');
          }
        ))
    );

    return selected;
  }

  async getResults() {
    const url = 'https://starcar-jobtool.abdtest.de/backend/api/jobs';
    const token = 'SLlXvE7kmWZw48PnWe4qn2t9ChhWGoTDsSNhkgFG';

    const body = this.selectedTranslated;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const results = await response.json();

    this.results = results.data;
    this.isLoading = false;
  }

  root: HTMLElement | null = null;

  $refs!: {
    dialog: any,
  }

  mounted() {
    this.root = document.querySelector('body > .root');

    this.$refs.dialog.addEventListener('close', this.closeDialog);

    this.getResults();
  }

  openDialog() {
    this.$refs.dialog.showModal();

    const scrollY = window.scrollY;
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;

    this.root?.setAttribute('inert', '');
  }

  closeDialog() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.root?.removeAttribute('inert');
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.result {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-block: 3rem;
  padding-block: 1.25rem;
  row-gap: 2rem;

  @media (min-width: 960px) {
    padding: 0;
  }
}

.result__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.result__logo {
  block-size: 3rem;
  inline-size: 17.5rem;
}

.result__headline {
  font-size: 2.5rem;
  font-weight: 700;
  hyphens: auto;
  line-height: 1.4;
  text-transform: uppercase;
}

.result__loader {
  align-self: center;
  aspect-ratio: 1 / 1;
  block-size: 3rem;
  inline-size: 3rem;
  stroke: $black;
}

.result__wrapper {
  background-color: $gray-3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  justify-content: center;
  padding-block: 2rem;

  @media (min-width: 640px) {
    min-block-size: 28rem;
  }

  @media (min-width: 768px) {
    min-block-size: 28.1875rem;
  }

  @media (min-width: 960px) {
    min-block-size: 31rem;
  }

  @media (min-width: 1536px) {
    min-block-size: 40rem;
  }
}

.result__winners {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.result__winners::before {
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 1) 55%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  display: block;
  grid-column: 1 / 3;
  grid-row: 1 / 4;
  z-index: 10;
}

.result__winner {
  align-items: flex-end;
  column-gap: 2rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    align-items: center;
    flex-direction: row;
  }
}

.result__winner:nth-child(1) {
  grid-column: 1 / 9;
  grid-row: 1;

  @media (min-width: 768px) {
    grid-column: 1 / 8;
  }

  @media (min-width: 960px) {
    grid-column: 1 / 10;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / 9;
  }

  @media (min-width: 1536px) {
    grid-column: 1 / 8;
  }
}

.result__winner:nth-child(2) {
  grid-column: 1 / 11;
  grid-row: 2;

  @media (min-width: 768px) {
    grid-column: 1 / 10;
  }

  @media (min-width: 960px) {
    grid-column: 1 / 12;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / 11;
  }

  @media (min-width: 1536px) {
    grid-column: 1 / 10;
  }
}

.result__winner:nth-child(3) {
  grid-column: 1 / 8;
  grid-row: 3;

  @media (min-width: 768px) {
    grid-column: 1 / 7;
  }

  @media (min-width: 960px) {
    grid-column: 1 / 9;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / 8;
  }

  @media (min-width: 1536px) {
    grid-column: 1 / 7;
  }
}

.result__visual {
  block-size: 6rem;
  flex: 1 1 100%;

  @media (min-width: 960px) {
    block-size: 9rem;
  }

  @media (min-width: 1536px) {
    block-size: 12rem;
  }
}

.result__visual > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
  object-position: right;
}

.result__info {
  font-size: 0.875rem;
  line-height: 1.1;
  text-align: right;

  @media (min-width: 640px) {
    text-align: left;
  }

  @media (min-width: 960px) {
    font-size: 1rem;
  }
}

.result__info > h3 {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  @media (min-width: 960px) {
    font-size: 1.25rem;
  }
}

.result__nav {
  display: flex;
  flex-direction: column;
  inline-size: calc(100% - 2.5rem);
  max-inline-size: 50rem;
  row-gap: 1.875rem;

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;

    > :deep(button:nth-child(1)) {
      order: 3;
    }

    > :deep(button:nth-child(2)) {
      order: 2;
    }

    > :deep(button:nth-child(3)) {
      order: 1;
    }
  }
}

.result__close {
  align-items: center;
  aspect-ratio: 1 / 1;
  border: 1px solid $black;
  border-radius: 9999px;
  block-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;

  > svg {
    block-size: 1rem;
  }

  @media only screen and (min-width: 960px) {
    top: 30px;
    right: 30px;
  }
}

.dialog {
  background-color: $white;
  block-size: calc(100vh - 8rem);
  border: none;
  box-sizing: border-box;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  inline-size: calc(100vw - 8rem);
  margin: 4rem 0 0 4rem;
  padding: 0;
  position: relative;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.dialog__inner {
  align-items: center;
  background-color: $yellow-1;
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  padding: 3rem 2rem;
  position: absolute;
  row-gap: 2rem;

  @media only screen and (min-width: 960px) {
    row-gap: 3rem;
  }
}

.dialog__inner > * {
  margin-block: 0;
}

.dialog__logo {
  block-size: 3rem;
  inline-size: 17.5rem;
}

.dialog__title {
  max-inline-size: 65rem;
}

.dialog__title > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}

.dialog__qr-code {
  aspect-ratio: 1 / 1;
  block-size: 10rem;
  margin-block-start: 2rem;

  @media only screen and (min-width: 960px) {
    block-size: 15rem;
    margin-block-start: 0;
  }
}

.dialog__qr-code > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}

.dialog__close {
  all: unset;
  align-items: center;
  aspect-ratio: 1 / 1;
  border: 1px solid $black;
  border-radius: 9999px;
  block-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 30px;
}

.dialog__close > svg {
  block-size: 1rem;
}
</style>
