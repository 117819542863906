<template>
  <div class="overlay">
    <p class="overlay__headline" v-html="title"></p>
    <p
      class="overlay__text"
      v-html="$t([stepsModule.state.stepCurrent.type, 'overlayText'])"
    ></p>
    <Button
      class="overlay__btn-next"
      :text="$t(['general', 'buttonUnderstand'])"
      @on-click="onButton"
    />
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStepSelectElectricity, IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Button from './Button.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  emits: ['on-close'],
  components: {
    Button,
  },
})
export default class Overlay extends mixins(Vue.props(Props), TranslationHandler) {
  get isNextInstant(): boolean {
    // Because of the very sepecific functionality this logic is defined here
    return this.stepsModule.state.stepCurrent.type === IStepType.ELECTRICITY
  }

  get title(): string {
    // Because of the very sepecific functionality this logic is defined here
    const electricitySelected = (this.stepsModule.state.selected[IStepType.ELECTRICITY] as IStepSelectElectricity[]) || []

    if (electricitySelected.includes(IStepSelectElectricity.WATER)) {
      return this.$t([this.stepsModule.state.stepCurrent.type, 'overlayTitle2'])
    }

    return this.$t([this.stepsModule.state.stepCurrent.type, 'overlayTitle'])
  }

  onButton() {
    if (this.isNextInstant === true) {
      this.stepsModule.next()
    }
    this.$emit('on-close')
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.overlay {
  // background: $blue-2;
  // color: $white;
  background: $white;
  color: $blue-2;
  border: 1px solid $blue-2;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding: 60px 40px 40px;
  top: 0;
  left: 0;

  @media only screen and (min-width: 900px) {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: auto;
    height: auto;
  }
  &__headline {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 1.2;
  }
  &__btn {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid $blue-2;
    border-radius: 50px;
    background: transparent;
    width: 30px;
    height: 30px;
    fill: $blue-2;
    padding: 7px;

    svg {
      vertical-align: baseline;
    }
  }
  &__btn-next {
    margin-top: 20px;
  }
}
</style>
