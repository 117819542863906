
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  text = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: Boolean,
    required: true,
  })
}

@Options({
  emits: ['on-click'],
})
export default class Checkbox extends Vue.props(Props) {
  onClick(event: Event) {
    event.preventDefault()

    this.$emit('on-click')
  }
}
