import { IOption, IStep, IStepSelect, IStepSelectSkip, IStepsSelected, IStepTemplate } from '@/types'
import { Options, Vue } from 'vue-class-component'

@Options({})
export class OptionsHandler extends Vue {
  $optionsGet(step: IStep, selectedAll: IStepsSelected, $t: (keys: string[]) => string): IOption[] {
    const selected: string[] = selectedAll[step.type] || []
    const options = this.$optionsGetStepOptions(step, selectedAll).map((option) => ({
      id: option,
      text: $t([step.type, 'options', option]),
      isSelected: selected.includes(option),
      iconKeys: step.template === IStepTemplate.TILES ? ([step.type, option] as string[]) : undefined,
    }))

    if (step.isSkip) {
      options.push({
        id: IStepSelectSkip,
        text: $t([step.type, 'optionSkip']),
        isSelected: selected.includes(IStepSelectSkip),
        iconKeys: step.template === IStepTemplate.TILES ? [step.type, IStepSelectSkip] : undefined,
      })
    }

    return options
  }

  $optionsGetStepOptions(step: IStep, selected: IStepsSelected): IStepSelect[] {
    if (step.options) {
      return step.options
    }

    if (step.optionsMapType && step.optionsMap) {
      const optionsMapType = step.optionsMapType.find((type) => selected[type])

      if (optionsMapType) {
        const selectedOptionMapType = selected[optionsMapType][0]

        return step.optionsMap[selectedOptionMapType]
      }
    }

    return []
  }
}
