<template>
  <div class="contact">
    <ul>
      <li v-for="step in steps" :key="step.type">
        <Dropdown
          :isAlternative="true"
          :placeholder="
            $t([stepsModule.state.stepCurrent.type, 'labels', step.type])
          "
          :isCheckbox="true"
          :options="getOptions(step)"
          @select="stepsModule.toggleOption($event, step)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { OptionsHandler } from '@/mixins/options-handler';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStep } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import Dropdown from './Dropdown.vue'
import Button from './Button.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Dropdown,
    Button,
  }
})
export default class Overview extends mixins(Vue.props(Props), TranslationHandler, OptionsHandler) {
  get steps(): (IStep | undefined)[] {
    return Object
      .keys(this.stepsModule.state.selected)
      .map((stepType) => {
        return this.stepsModule.state.steps.find((s) => s.type === stepType)
      })
      .filter((step) => step !== undefined)
  }

  getOptions(step: IStep) {
    return this.$optionsGet(step, this.stepsModule.state.selected, this.$t)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";
ul {
  @media only screen and (min-width: 960px) {
    display: grid;
    gap: 15px 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      ". ."
      ". .";
    width: 120%;
    transform: translateX(-10%);
  }
}
li {
  .dropdown-container {
    margin-bottom: 25px;
    @media only screen and (min-width: 960px) {
      margin-bottom: 0;
    }
  }
}
</style>
