
import { StepsModule } from '@/composables/use-steps';
import { IStepTemplate } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler'
import Button from './Button.vue'
import ProgressBar from './ProgressBar.vue'
import Tooltip from './Tooltip.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  emits: ['on-next', 'on-back'],
  components: {
    Button,
    ProgressBar,
    Tooltip,
  },
})
export default class StepEmpty extends mixins(Vue.props(Props), TranslationHandler) {
  isTooltipShown = false

  get styleWrapper() {
    return {
      'max-inline-size': this.stepsModule.state.stepCurrent.template === IStepTemplate.CALENDAR ? '100%' : '32rem',
    }
  }

  get isContactForm() {
    return this.stepsModule.state.stepCurrent.template === IStepTemplate.CONTACT;
  }

  get isNavigationShown(): boolean {
    return [IStepTemplate.TILES, IStepTemplate.DROPDOWN_SELECT, IStepTemplate.OVERVIEW, IStepTemplate.CALENDAR]
      .includes(this.stepsModule.state.stepCurrent.template)
  }

  get isProgressBarShown(): boolean {
    return [IStepTemplate.THANK_YOU]
      .includes(this.stepsModule.state.stepCurrent.template) === false
  }

  get hint(): string | null {
    if (this.stepsModule.state.stepCurrent.options === undefined && this.stepsModule.state.stepCurrent.optionsMap === undefined) {
      return null
    }

    return this.stepsModule.state.stepCurrent.isMultiple
      ? this.$t(['general', 'multipleChoice'])
      : this.$t(['general', 'singleChoice'])

  }
}
