<template>
  <div class="thank-you">
    <svg
      title="Starcar"
      aria-labelledby="svg-logo"
      role="img"
      class="thank-you-logo"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 280 48"
    >
      <title id="svg-logo">Starcar</title>
      <path
        d="M15.08 47.63c5.696 0 15.547-1.576 17.41-12.25 1.54-8.598-1.792-13.505-10.675-15.87-3.725-.966-9.314-2.435-8.561-6.196.465-2.221 2.507-3.546 5.373-3.546 2.579 0 5.48 1.074 7.737 2.865.358-.824 1.003-2.041 1.648-3.331L30.662 4A33.529 33.529 0 0 0 17.123.597C16.004.6 14.89.707 13.791.92a14.078 14.078 0 0 0-12.18 13.97c-.357 6.34 2.222 9.958 8.598 12.072l2.436.716a81.166 81.166 0 0 1 5.445 1.827c1.468.573 2.614 1.863 2.937 3.295.286 1.326-.108 2.651-1.146 3.762a6.448 6.448 0 0 1-4.872 1.898c-3.224 0-7.057-1.648-10.316-4.477L3.26 36.705 0 42.759c1.433 1.003 7.307 4.8 14.615 4.907h.466v-.036ZM65.983 1.636H35.391l.036 9.385h9.886l.036 35.176h11.069V10.95h9.564V1.636Zm28.442-.143H82.137L65.015 46.197h11.857l3.009-7.88H96.5l2.974 7.88h11.785L94.424 1.493ZM83.57 29.577l4.514-13.648 4.513 13.612H83.57v.035ZM220.55 1.17H207.94l-17.48 45.062h12.107l3.152-8.131h16.657l3.045 8.095h11.821L220.549 1.171ZM209.66 29.183l4.513-13.397 4.514 13.432h-9.027v-.035Zm-23.069 15.976V32.156a14.259 14.259 0 0 1-10.245 4.12c-3.188 0-6.161-1.255-8.418-3.547a13.258 13.258 0 0 1-3.582-8.884c0-6.376 3.761-12.895 11.785-12.895a13.609 13.609 0 0 1 10.711 4.656V1.994a31.775 31.775 0 0 0-12.323-1.97c-5.659.287-21.85 5.266-21.994 23.57a21.852 21.852 0 0 0 6.269 16.156 26.866 26.866 0 0 0 17.266 7.88c5.48 0 9.277-1.79 10.531-2.507v.036Zm77.875-17.481 1.218-.143c.358-.036 9.134-1.791 9.134-13.146 0-12.144-11.391-13.003-13.612-13.04l-19.164-.035v44.92h11.212v-18.27l11.498 18.27h14.042l-14.257-18.556h-.071Zm-10.675-6.663V10.376s8.884-1.325 8.884 5.517c0 6.34-8.884 5.122-8.884 5.122ZM151.988 46.197l-13.755-18.34.824-.143c.358-.072 9.313-1.791 9.17-13.146-.215-12.18-11.427-13.003-13.648-13.04h-19.164l-.036 44.67h11.319v-18.09l11.284 18.09h14.006Zm-24.86-25.003V10.557s8.956-1.326 8.92 5.48c0 6.376-8.92 5.159-8.92 5.159Z"
      />
    </svg>
    <figure class="thank-you-title">
      <img
        src="/thank-you-title.png"
        alt="Vielen Dank für Ihre Teilnahme!"
        width="773"
        height="247"
      />
    </figure>
    <div class="thank-you-content">
      <p>
        {{ $t([stepsModule.state.stepCurrent.type, 'text1']) }}
      </p>
      <Button
        @on-click="goHome()"
        :tertiary="true"
        :text="$t([stepsModule.state.stepCurrent.type, 'btn'])"
      />
    </div>
    <figure class="thank-you-visual">
      <img
        src="/hands.png"
        alt="Zwei Hände, die ein Herz formen"
        width="935"
        height="354"
      />
    </figure>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler';
import Button from './Button.vue';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button
  },
})
export default class ThankYou extends mixins(TranslationHandler, Vue.props(Props)) {
  goHome() {
    window.open(this.$t([this.stepsModule.state.stepCurrent.type, 'btnLnk']), '_self')
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.thank-you {
  align-items: center;
  background-color: $yellow-1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-block: 4rem 0;
  padding-inline: 3rem;
  row-gap: 4rem;
}

.thank-you > * + *  {
  margin-block: 0;
}

.thank-you-logo {
  block-size: 3rem;
  inline-size: 17.5rem;
}

.thank-you-title {
  max-inline-size: 48rem;
}

.thank-you-title > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}

.thank-you-content {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.4;
  max-inline-size: 60ch;
  row-gap: 2rem;
  text-align: center;
}

.thank-you-content > * + *  {
  margin-block: 0;
}

.thank-you-content > p:first-of-type  {
  font-size: 1.5rem;
  font-weight: 700;
}

.thank-you-content > :deep(button)  {
  align-self: center;
}

.thank-you-visual {
  margin-block-start: auto;
  max-inline-size: 62rem;
}

.thank-you-visual > img {
  block-size: 100%;
  display: block;
  inline-size: 100%;
  object-fit: cover;
}
</style>
