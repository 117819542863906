<template>
  <div class="steps">
    <div class="steps__header">
      <svg
        title="Starcar"
        aria-labelledby="svg-logo"
        role="img"
        class="steps__logo"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 280 48"
      >
        <title id="svg-logo">Starcar</title>
        <path
          d="M15.08 47.63c5.696 0 15.547-1.576 17.41-12.25 1.54-8.598-1.792-13.505-10.675-15.87-3.725-.966-9.314-2.435-8.561-6.196.465-2.221 2.507-3.546 5.373-3.546 2.579 0 5.48 1.074 7.737 2.865.358-.824 1.003-2.041 1.648-3.331L30.662 4A33.529 33.529 0 0 0 17.123.597C16.004.6 14.89.707 13.791.92a14.078 14.078 0 0 0-12.18 13.97c-.357 6.34 2.222 9.958 8.598 12.072l2.436.716a81.166 81.166 0 0 1 5.445 1.827c1.468.573 2.614 1.863 2.937 3.295.286 1.326-.108 2.651-1.146 3.762a6.448 6.448 0 0 1-4.872 1.898c-3.224 0-7.057-1.648-10.316-4.477L3.26 36.705 0 42.759c1.433 1.003 7.307 4.8 14.615 4.907h.466v-.036ZM65.983 1.636H35.391l.036 9.385h9.886l.036 35.176h11.069V10.95h9.564V1.636Zm28.442-.143H82.137L65.015 46.197h11.857l3.009-7.88H96.5l2.974 7.88h11.785L94.424 1.493ZM83.57 29.577l4.514-13.648 4.513 13.612H83.57v.035ZM220.55 1.17H207.94l-17.48 45.062h12.107l3.152-8.131h16.657l3.045 8.095h11.821L220.549 1.171ZM209.66 29.183l4.513-13.397 4.514 13.432h-9.027v-.035Zm-23.069 15.976V32.156a14.259 14.259 0 0 1-10.245 4.12c-3.188 0-6.161-1.255-8.418-3.547a13.258 13.258 0 0 1-3.582-8.884c0-6.376 3.761-12.895 11.785-12.895a13.609 13.609 0 0 1 10.711 4.656V1.994a31.775 31.775 0 0 0-12.323-1.97c-5.659.287-21.85 5.266-21.994 23.57a21.852 21.852 0 0 0 6.269 16.156 26.866 26.866 0 0 0 17.266 7.88c5.48 0 9.277-1.79 10.531-2.507v.036Zm77.875-17.481 1.218-.143c.358-.036 9.134-1.791 9.134-13.146 0-12.144-11.391-13.003-13.612-13.04l-19.164-.035v44.92h11.212v-18.27l11.498 18.27h14.042l-14.257-18.556h-.071Zm-10.675-6.663V10.376s8.884-1.325 8.884 5.517c0 6.34-8.884 5.122-8.884 5.122ZM151.988 46.197l-13.755-18.34.824-.143c.358-.072 9.313-1.791 9.17-13.146-.215-12.18-11.427-13.003-13.648-13.04h-19.164l-.036 44.67h11.319v-18.09l11.284 18.09h14.006Zm-24.86-25.003V10.557s8.956-1.326 8.92 5.48c0 6.376-8.92 5.159-8.92 5.159Z"
        />
      </svg>
      <h2 class="steps__header__title">
        {{ $t(["general", "title"]) }}
      </h2>
      <ProgressBar
        v-if="isProgressBarShown"
        :progress="stepsModule.state.stepProgressPercentage"
      />
    </div>
    <div class="steps__stage" :class="{'is-contact-form': isContactForm}">
      <div class="wrapper" :style="styleWrapper">
        <div class="steps__stage__title">
          {{ $t([stepsModule.state.stepCurrent.type, "headline"]) }}

          <Tooltip
            :stepsModule="stepsModule"
            v-if="stepsModule.state.stepCurrent.isTooltip"
          />
        </div>
        <p class="steps__stage__hint" v-if="hint">
          {{ hint }}
        </p>
        <slot></slot>
      </div>
    </div>
    <div class="steps__control-strip" v-if="isNavigationShown">
      <Button
        id="button-back"
        :secondary="true"
        :text="$t(['general', 'buttonBack'])"
        @on-click="stepsModule.back"
        :disabled="!stepsModule.state.isBackAllowed"
      />
      <Button
        id="button-next"
        @on-click="stepsModule.next"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
        :text="$t(['general', 'buttonNextStep'])"
      />
    </div>
    <div class="steps__close" @click="stepsModule.backHome">
      <svg
        title="Schließen"
        aria-labelledby="svg-close"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <title id="svg-close">Schließen</title>
        <path
          d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { IStepTemplate } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '../mixins/translation-handler'
import Button from './Button.vue'
import ProgressBar from './ProgressBar.vue'
import Tooltip from './Tooltip.vue'

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  emits: ['on-next', 'on-back'],
  components: {
    Button,
    ProgressBar,
    Tooltip,
  },
})
export default class StepEmpty extends mixins(Vue.props(Props), TranslationHandler) {
  isTooltipShown = false

  get styleWrapper() {
    return {
      'max-inline-size': this.stepsModule.state.stepCurrent.template === IStepTemplate.CALENDAR ? '100%' : '32rem',
    }
  }

  get isContactForm() {
    return this.stepsModule.state.stepCurrent.template === IStepTemplate.CONTACT;
  }

  get isNavigationShown(): boolean {
    return [IStepTemplate.TILES, IStepTemplate.DROPDOWN_SELECT, IStepTemplate.OVERVIEW, IStepTemplate.CALENDAR]
      .includes(this.stepsModule.state.stepCurrent.template)
  }

  get isProgressBarShown(): boolean {
    return [IStepTemplate.THANK_YOU]
      .includes(this.stepsModule.state.stepCurrent.template) === false
  }

  get hint(): string | null {
    if (this.stepsModule.state.stepCurrent.options === undefined && this.stepsModule.state.stepCurrent.optionsMap === undefined) {
      return null
    }

    return this.stepsModule.state.stepCurrent.isMultiple
      ? this.$t(['general', 'multipleChoice'])
      : this.$t(['general', 'singleChoice'])

  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.steps {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin: 3rem auto;
  max-inline-size: 50rem;
  padding: 1.25rem;
  row-gap: 2rem;

  @media only screen and (min-width: 960px) {
    padding: 0;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    svg {
      block-size: 3rem;
    }

    &__title {
      font-size: 2.5rem;
      font-weight: 700;
      hyphens: auto;
      line-height: 1.4;
      margin-block: 0 1rem;
      text-transform: uppercase;
    }
  }

  &__stage {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .wrapper {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      row-gap: 1rem;
    }

    @media only screen and (min-width: 960px) {
      box-sizing: border-box;
      background-color: $gray-3;
      padding: 3rem 0;
      min-height: 32rem;

      &.is-contact-form {
        background-color: $white;
      }
    }

    &__title {
      align-items: baseline;
      column-gap: 1rem;
      display: flex;
      font-weight: 700;
      font-size: 1.5rem;
      justify-content: center;
      line-height: 1.4;
      text-align: center;
      margin-block: 0;
    }

    &__hint {
      font-size: 14px;
      color: $gray-2;
      margin-block: 0 1rem;
      text-align: center;
    }
  }

  &__control-strip {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .button {
      margin-block-end: $spacing-2;

      &:first-child {
        order: 2;
      }

      @media only screen and (min-width: 960px) {
        margin-block-end: 0;

        &:first-child {
          order: inherit;
        }
      }
    }

    p {
      color: $gray-2;
      font-size: 0.875rem;
      order: 3;
      text-align: center;

      @media only screen and (min-width: 960px) {
        order: inherit;
        text-align: inherit;
      }

      span {
        border-bottom: 1px solid $gray-2;
        color: inherit;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &__close {
    align-items: center;
    aspect-ratio: 1 / 1;
    border: 1px solid $black;
    border-radius: 9999px;
    block-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;

    > svg {
      block-size: 1rem;
    }

    @media only screen and (min-width: 960px) {
      top: 30px;
      right: 30px;
    }
  }
}
</style>
