
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler';
import { IStepsSelected } from '@/types';
import Button from './Button.vue';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true
  });
}

@Options({
  components: {
    Button
  }
})
export default class StepResult extends mixins(
  Vue.props(Props),
  TranslationHandler
) {
  results = [
    {
      title: 'Beruf',
      location: 'Standort',
    },
    {
      title: 'Beruf',
      location: 'Standort',
    },
    {
      title: 'Beruf',
      location: 'Standort',
    },
  ];

  isLoading = true;

  get selectedTranslated(): {} {
    const selected: IStepsSelected = {};

    Object.keys(this.stepsModule.state.selected).forEach(
      key =>
        (selected[key] = this.stepsModule.state.selected[key].map(
          selectedKey => {
            const value = this.$t([key, 'options', selectedKey]);

            return value.replace(/<\/?[^>]+(>|$)/g, '');
          }
        ))
    );

    return selected;
  }

  async getResults() {
    const url = 'https://starcar-jobtool.abdtest.de/backend/api/jobs';
    const token = 'SLlXvE7kmWZw48PnWe4qn2t9ChhWGoTDsSNhkgFG';

    const body = this.selectedTranslated;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const results = await response.json();

    this.results = results.data;
    this.isLoading = false;
  }

  root: HTMLElement | null = null;

  $refs!: {
    dialog: any,
  }

  mounted() {
    this.root = document.querySelector('body > .root');

    this.$refs.dialog.addEventListener('close', this.closeDialog);

    this.getResults();
  }

  openDialog() {
    this.$refs.dialog.showModal();

    const scrollY = window.scrollY;
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;

    this.root?.setAttribute('inert', '');
  }

  closeDialog() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    this.root?.removeAttribute('inert');
  }
}
