
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  disabled = prop({
    type: Boolean,
    default: false
  });
  secondary = prop({
    type: Boolean
  });
  tertiary = prop({
    type: Boolean
  });
  big = prop({
    type: Boolean
  });
  icon = prop({
    type: Boolean
  });
  text = prop({
    type: String
  });
}

@Options({
  emits: ['on-click']
})
export default class Button extends Vue.props(Props) {}
