export interface IStep {
  type: IStepType
  layout: IStepLayout
  template: IStepTemplate
  isTooltip?: boolean
  isMultiple?: boolean
  isSkip?: boolean
  isOverlay?: boolean
  options?: IStepSelect[]
  optionsMapType?: IStepType[]
  optionsMap?: {
    [k: string]: IStepSelect[]
  }
}

export enum IStepLayout {
  SPLASH = 'SPLASH',
  RESULT = 'RESULT',
  HOME = 'HOME',
  STEP_EMPTY = 'STEP_EMPTY', // rename
  THANK_YOU = 'THANK_YOU',
}

export enum IStepTemplate {
  SPLASH = 'SPLASH',
  RESULT = 'RESULT',
  TILES = 'TILES',
  DROPDOWN_SELECT = 'DROPDOWN_SELECT',
  OVERVIEW = 'OVERVIEW',
  CALENDAR = 'CALENDAR',
  CONTACT = 'CONTACT',
  THANK_YOU = 'THANK_YOU',
}

export enum IStepType {
  SPLASH = 'SPLASH',
  STARCAR_01_TYPE = 'STARCAR_01_TYPE',
  STARCAR_02_TYPE = 'STARCAR_02_TYPE',
  STARCAR_03_TYPE = 'STARCAR_03_TYPE',
  STARCAR_04_TYPE = 'STARCAR_04_TYPE',
  STARCAR_05_TYPE = 'STARCAR_05_TYPE',
  STARCAR_06_TYPE = 'STARCAR_06_TYPE',
  STARCAR_07_TYPE = 'STARCAR_07_TYPE',
  STARCAR_08_TYPE = 'STARCAR_08_TYPE',
  RESULT = 'RESULT',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  MACHINE_TYPE = 'MACHINE_TYPE',
  SERVICE = 'SERVICE',
  ELECTRICITY = 'ELECTRICITY',
  BRANCH = 'BRANCH',
  EMPLOYEES = 'EMPLOYEES',
  SHIFT_SYSTEM = 'SHIFT_SYSTEM',
  PAYMENT = 'PAYMENT',
  INSTALLATION = 'INSTALLATION',
  REGION = 'REGION',
  OVERVIEW = 'OVERVIEW',
  CALENDAR = 'CALENDAR',
  CONTACT_LONG = 'CONTACT_LONG',
  CONTACT_SHORT = 'CONTACT_SHORT',
  THANK_YOU = 'THANK_YOU',
  SUBPAGE_HOME = 'SUBPAGE_HOME',
  FOOTER_HOME = 'FOOTER_HOME',
}

export enum IStepLandingIconKeys {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  LIST_ARROW = 'LIST_ARROW',
}

export enum IStepFooterHomeIconKeys {
  OVERLAY = 'OVERLAY',
}

export enum IStepSelectStarcar01Type {
  HAMBURG = 'Hamburg',
  SOLINGEN = 'Solingen',
  MONHEIM = 'Monheim',
  GERMANY_WIDE = 'Deutschlandweit',
}

export enum IStepSelectStarcar02Type {
  YES = 'Ja',
  NO = 'Nein',
}

export enum IStepSelectStarcar03Type {
  TECHNOPHILE = 'technikbegeistert',
  SERVICE_ORIENTED = 'serviceorientiert',
  NUMBER_CRUNCHING = 'zahlenaffin',
  AUTOMOTIVE = 'automobilaffin',
}

export enum IStepSelectStarcar04Type {
  YES = 'Ja',
  NO = 'Nein',
}

export enum IStepSelectStarcar05Type {
  FLEXIBLE = 'flexibel',
  ORGANIZED = 'organisiert &amp; strukturiert',
}

export enum IStepSelectStarcar06Type {
  CHALLENGES = 'neue Herausforderungen',
  OVERVIEW = 'immer den Überblick',
  FLEXIBILITY = 'absolute Flexibilität',
}

export enum IStepSelectStarcar07Type {
  COMMERCIAL = 'Kaufmännische / BWL Ausbildung',
  TECHNICAL = 'Technische / IT-Ausbildung',
  FORWARDING = 'Speditionskaufmann / -frau',
  STUDY = 'Studium',
}

export enum IStepSelectStarcar08Type {
  YEARS_5_PLUS = '5+ Jahre',
  YEARS_3_TO_5 = '3-5 Jahre',
  YEARS_1_TO_2 = '1-2 Jahre',
  YEARS_LESS_1 = 'weniger als 1 Jahr',
}

export enum IStepSelectProductType {
  HOT_DRINK = 'hot-drink',
  SNACK = 'snack',
  WATER = 'water',
  COLD_DRINK = 'cold-drink',
  SOUP = 'soup',
  FRESH = 'fresh',
}

export enum IStepSelectMachineType {
  HOT_DRINK = 'hot-drink',
  CATERING = 'catering',
  WATER = 'water',
  COLD_DRINK = 'cold-drink',
  SNACK = 'snack',
}

export enum IStepSelectService {
  USER_FRIENDLINESS = 'user-friendliness',
  PRODUCT_RANGE = 'product-range',
  TECHNICAL_SERVICE = 'technical-service',
  HYGIENE = 'hygiene',
  PRICE_VALUE = 'price-value',
}

export enum IStepSelectBranch {
  PRODUCTION_LOGISTICS = 'logistics-logistics',
  HEALTH = 'health',
  PUBLIC_OR_EDUCATION = 'public-or-education',
  GASTRONOMY = 'gastronomy',
  CATERING = 'catering',
  OTHER = 'other',
}

export enum IStepSelectElectricity {
  ELECTRICITY_AND_WATER = 'electricity-and-water',
  ELECTRICITY = 'electricity',
  WATER = 'water',
  NONE = 'none-above',
}

export enum IStepSelectEmployees {
  BIG_1 = '1-29',
  BIG_2 = '30-99',
  BIG_3 = '100-499',
  BIG_4 = '500+',
  SMALL_1 = '1-49',
  SMALL_2 = '50-99',
  SMALL_3 = '100-249',
  SMALL_4 = '250+',
}

export enum IStepSelectShiftSystem {
  NONE = 'none',
  SHIFT_2 = '2-shift',
  SHIFT_3 = '3-shift',
  SHIFT_4 = '4-shift',
}

export enum IStepSelectPayment {
  CARD = 'card',
  CASH = 'cash',
  OTHER = 'other',
}

export enum IStepSelectInstallation {
  SELF = 'self',
  SERVICE = 'service',
  UNKNOW = 'unkown',
}

export enum IStepSelectRegion {
  DE = 'de',
  AUS = 'aus',
  CH = 'ch',
  OTHER = 'other',
}

export enum IPreselectStepRoute {
  BRANCH = 'BRANCH',
  PRODUCT = 'PRODUCT'
}

export const IStepSelectSkip: string = 'skip'

export type IStepSelect = string |
  IStepSelectProductType |
  IStepSelectMachineType |
  IStepSelectBranch |
  IStepSelectService |
  IStepSelectElectricity |
  IStepSelectEmployees |
  IStepSelectShiftSystem |
  IStepSelectPayment |
  (typeof IStepSelectSkip)

export interface IStepsSelected {
  [key: string]: IStepSelect[]
}

export interface IOption {
  id: string
  text: string
  isSelected: boolean
  iconKeys?: string[]
}

export interface IMap {
  [k: string]: string | string[]
}

export enum IHomeStyle {
  HOME_PAGE = 'home-page',
  PRE_FOOTER = 'pre-footer',
  SUB_PAGE = 'sub-page',
}
