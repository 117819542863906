import { IStepType } from '@/types'

interface DataLayerEvent {
  event: string,
  pageType: string
}

declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

export interface TrackingModule {
  push: (stepType: IStepType, routeNum?: number) => void
}

export function useTracking(): TrackingModule {
  const eventMap: { [k: string]: string } = {
    [IStepType.PRODUCT_TYPE]: 'Auswahl-Automaten',
    [IStepType.MACHINE_TYPE]: 'Getraenke-und-Verpflegungsautomat',
    [IStepType.SERVICE]: 'Service',
    [IStepType.ELECTRICITY]: 'Festwasseranschluss',
    [IStepType.BRANCH]: 'Branche',
    [IStepType.EMPLOYEES]: 'Mitarbeiteranzahl',
    [IStepType.SHIFT_SYSTEM]: 'Schichtsystem',
    [IStepType.PAYMENT]: 'Zahlungsmoeglichkeiten',
    [IStepType.INSTALLATION]: 'Bewirtschaftung',
    [IStepType.REGION]: 'Aufstellungsort',
    [IStepType.OVERVIEW]: 'Zusammenfassung',
    [IStepType.CONTACT_LONG]: 'Kontakformular-1',
    [IStepType.CONTACT_SHORT]: 'Kontakformular-2',
    [IStepType.THANK_YOU]: 'Danke',
    [IStepType.CALENDAR]: 'Calendly',
  }

  function getPageType(stepType: IStepType, routeNum: number | undefined) {
    if (routeNum === 3) {
      // route num 3 is always skip to contact
      return 'Kontakformular-3'
    }
    if (routeNum === undefined) {
      return eventMap[stepType]
    }

    return `Route-${routeNum}-${eventMap[stepType]}`
  }

  async function push(stepType: IStepType, routeNum: number | undefined = undefined) {
    window.dataLayer.push({
      event: 'PageStep',
      pageType: getPageType(stepType, routeNum),
    });
  }

  return {
    push,
  }
}
