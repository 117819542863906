
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  placeholder = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: String,
    required: true,
  })

  type = prop({
    type: String,
    default: 'text',
  })

  error = prop({
    type: Boolean,
    default: false,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    text(text: string) {
      this.$emit('on-change', text)
    }
  }
})
export default class Input extends Vue.props(Props) {
  text: string = ''

  created() {
    this.text = this.value
  }

  get classNames() {
    return [
      { 'input--active': (this.text.length > 0) },
      { 'input--error': this.error },
    ]
  }
}
