<template>
  <div :id="appId" class="css-reset">
    <Step
      :appId="appId"
      :stepsModule="stepsModule"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue, setup, prop } from 'vue-class-component'
import Step from './components/Step.vue'
import { useSteps } from './composables/use-steps'
import { TrackingModule, useTracking } from './composables/use-tracking'
import { IHomeStyle, IMap, IPreselectStepRoute, IStepType } from './types'

class Props {
  imageMap = prop({
    type: Object as () => IMap,
    required: true
  })
  fontMap = prop({
    type: Object as () => IMap,
    required: true
  })
  textMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectStepsRoute = prop({
    type: String as () => IPreselectStepRoute,
    default: IPreselectStepRoute.PRODUCT
  })
  homeStyle = prop({
    type: String as () => IHomeStyle,
    default: IHomeStyle.HOME_PAGE
  })
}

@Options({
  components: {
    Step,
  },
})
export default class App extends Vue.props(Props) {
  trackingModule: TrackingModule = useTracking()
  stepsModule = setup(() => useSteps(
    this.imageMap,
    this.homeStyle,
    this.textMap,
    this.preselectMap,
    this.preselectStepsRoute
  ))

  get appId(): string {
    return 'contact-route'
  }

  mounted() {
    const $style = document.createElement('style')

    $style.appendChild(document.createTextNode(`
      @font-face {
        font-family: "Futura";
        src: url("${this.fontMap['futura-condensed-bold']}") format("truetype");
        font-style: normal;
        font-weight: 700;
      }
    `));

    document.head.appendChild($style);

    this.trackingModule.push(IStepType.PRODUCT_TYPE)
  }
}
</script>

<style lang="scss">
.css-reset {
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}
</style>

<style lang="scss" scoped>
#contact-route {
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  min-block-size: 100vh;
  min-inline-size: 100vw;
}
</style>
