
import { StepsModule } from '@/composables/use-steps';
import { STEP_SELECT_OPTION_NOT_INSTANT } from '@/config/steps';
import { IOption, IStepSelect } from '@/types';
import { Options, Vue, prop } from 'vue-class-component';
import Tile from './Tile.vue'

class Props {
  options = prop({
    type: Object as () => IOption[],
    required: true,
  })

  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Tile,
  }
})

export default class Tiles extends Vue.props(Props) {
  onOption(tile: IOption) {
    this.stepsModule.toggleOption(tile.id, this.stepsModule.state.stepCurrent)

    const isOptionInstant = !STEP_SELECT_OPTION_NOT_INSTANT.includes((tile.id as IStepSelect))

    if (isOptionInstant && this.stepsModule.state.stepCurrent.isMultiple === false) {
      this.stepsModule.next()
    }
  }
}
