
import { Options, Vue, setup, prop } from 'vue-class-component'
import Step from './components/Step.vue'
import { useSteps } from './composables/use-steps'
import { TrackingModule, useTracking } from './composables/use-tracking'
import { IHomeStyle, IMap, IPreselectStepRoute, IStepType } from './types'

class Props {
  imageMap = prop({
    type: Object as () => IMap,
    required: true
  })
  fontMap = prop({
    type: Object as () => IMap,
    required: true
  })
  textMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectMap = prop({
    type: Object as () => IMap,
    required: true
  })
  preselectStepsRoute = prop({
    type: String as () => IPreselectStepRoute,
    default: IPreselectStepRoute.PRODUCT
  })
  homeStyle = prop({
    type: String as () => IHomeStyle,
    default: IHomeStyle.HOME_PAGE
  })
}

@Options({
  components: {
    Step,
  },
})
export default class App extends Vue.props(Props) {
  trackingModule: TrackingModule = useTracking()
  stepsModule = setup(() => useSteps(
    this.imageMap,
    this.homeStyle,
    this.textMap,
    this.preselectMap,
    this.preselectStepsRoute
  ))

  get appId(): string {
    return 'contact-route'
  }

  mounted() {
    const $style = document.createElement('style')

    $style.appendChild(document.createTextNode(`
      @font-face {
        font-family: "Futura";
        src: url("${this.fontMap['futura-condensed-bold']}") format("truetype");
        font-style: normal;
        font-weight: 700;
      }
    `));

    document.head.appendChild($style);

    this.trackingModule.push(IStepType.PRODUCT_TYPE)
  }
}
