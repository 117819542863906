<template>
  <div class="home">
    <div class="home__mood">
      <div class="home__mood__copy">
        <h2
          v-html="
            $t([stepsModule.state.stepCurrent.type, 'prefooter', 'headline'])
          "
        ></h2>
      </div>
      <div class="home__mood__image-overlay">
        <img :src="stepsModule.state.imageMap['homeLeft']" alt="" />
        <Icon class="home__mood__image-overlay--icon" :keys="overlayKeys" />
      </div>
    </div>
    <div class="home__select">
      <p class="home__select__headline">
        {{ $t([stepsModule.state.stepCurrent.type, "headline"]) }}
      </p>
      <span class="home__select__hint">
        {{ $t(["general", "multipleChoice"]) }}
      </span>

      <!-- Tiles -->
      <slot></slot>
      <!-- Tiles end -->

      <Button
        class="home__button"
        @on-click="stepsModule.next"
        :text="$t(['general', 'buttonNext'])"
        :disabled="!stepsModule.state.isNextAllowed"
        :icon="true"
      />

      <p class="home__select__skip-steps">
        {{ $t(["general", "contact"]) }}
        <span @click="stepsModule.nextContact">{{
          $t(["general", "contactClick"])
        }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler'
import Button from './Button.vue'
import Icon from '@/components/Icon.vue';
import { IStepFooterHomeIconKeys, IStepType } from '@/types';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button, Icon
  }
})
export default class StepPrefooterHome extends mixins(Vue.props(Props), TranslationHandler) {
  overlayKeys: Array<(IStepType.FOOTER_HOME | IStepFooterHomeIconKeys.OVERLAY)> = [IStepType.FOOTER_HOME, IStepFooterHomeIconKeys.OVERLAY];
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.home {
  background-color: #f5f5f5;
  @media only screen and (min-width: 1200px) {
    padding: 160px 130px;
    display: flex;
    flex-direction: row;
    min-height: 800px;
  }
  @media only screen and (max-width: 1200px) {
    padding: 100px 0;
  }

  > div {
    @media only screen and (min-width: 1200px) {
      flex: 1;
    }
  }

  &__mood {
    color: $white;
    font-size: 32px;
    line-height: 0;
    position: relative;

    &__copy {
      padding: 40px 20px;
      position: absolute;
      line-height: initial;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      h2 {
        font-weight: 600;
        font-size: 41px;
        line-height: 46px;
        @media only screen and (max-width: 1200px) {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }

    &__image-overlay {
      height: 100%;
      max-height: 760px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 500px;
        object-fit: cover;
        object-position: 0 10%;
        @media only screen and (min-width: 1200px) {
          object-position: 50% 50%;
          height: -webkit-fill-available;
          max-height: 760px;
        }
      }

      &--icon {
        position: absolute;
        z-index: 1;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 100%;

        ::v-deep(svg) {
          position: absolute;
          right: -5px;
          height: 100%;
        }
      }
    }

    &::after {
      content: "";
      height: 100%;
      width: 100%;
      background: linear-gradient(135deg, #002856, #110000);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }
    @media only screen and (max-width: 1200px) {
      margin: 20px;
    }
  }

  &__button {
    margin: 30px 0;
  }

  &__select {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      margin: 0 auto 30px;
      max-width: 400px;
    }

    &__headline {
      margin-top: 20px;
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      line-height: 32px;
      margin-bottom: 20px;
      max-width: 530px;
      padding: 0;
      color: $blue-1;
      @media only screen and (max-width: 1200px) {
        font-size: 24px;
      }
    }
    &__hint {
      font-size: 14px;
      color: $gray-2;
      margin-bottom: $spacing-3;
    }

    &__skip-steps {
      font-size: 14px;
      color: $gray-2;
      font-weight: 400;
      text-align: center;

      span {
        text-decoration: none;
        border-bottom: 1px solid $gray-2;
        color: inherit;
        cursor: pointer;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 500px) {
      .home__button {
        width: 100%;
      }
    }
    @media only screen and (max-width: 1200px) {
      margin: 20px;
      background-color: #f5f5f5;
    }
  }
}
</style>
