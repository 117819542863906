
import { StepsModule } from '@/composables/use-steps';
import { IMap, IStepLandingIconKeys, IStepType } from '@/types';
import { Options, Vue, prop, mixins } from 'vue-class-component';
import { TranslationHandler } from '@/mixins/translation-handler'
import Button from './Button.vue'
import Icon from './Icon.vue'


class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({
  components: {
    Button, Icon
  }
})
export default class StepSubpageHome extends mixins(Vue.props(Props), TranslationHandler) {
  listItemIconKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.LIST_ARROW)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.LIST_ARROW];
  overlayMobileKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.MOBILE)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.MOBILE];
  overlayDesktopKeys: Array<(IStepType.SUBPAGE_HOME | IStepLandingIconKeys.DESKTOP)> = [IStepType.SUBPAGE_HOME, IStepLandingIconKeys.DESKTOP];

  get textMap(): IMap {
    return this.stepsModule.state.textMap
  }
}
