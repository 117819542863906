<template>
  <div class="k-tooltip__wrapper" ref="tooltip">
    <svg
      class="k-tooltip__icon"
      @click="isTooltipShown = !isTooltipShown"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 20 20"
      xml:space="preserve"
    >
      <g transform="translate(-1190 -456)">
        <g>
          <circle
            cx="10"
            cy="10"
            r="10"
            fill="#fff"
            transform="translate(1190 456)"
          />
          <path
            class="st1"
            d="M10 20C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10zm0-19c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z"
            transform="translate(1190 456)"
          />
        </g>
        <path
          class="st1"
          d="M1198.5 469.5c0-.2.1-.4.2-.6l1-3.6v-.1-.1c0-.2-.1-.3-.2-.4-.1-.1-.4-.1-.7-.1v-.4c.3 0 .7-.1 1.3-.1.6-.1 1-.1 1.2-.2l.7-.1-1.2 4.6c-.1.4-.2.7-.2.8-.1.4-.1.6-.1.8s0 .2.1.2.1.1.2.1c.2 0 .4-.1.6-.4s.5-.6.6-.9l.4.2c-.5.8-.9 1.3-1.2 1.5-.5.4-.9.7-1.5.7-.3 0-.6-.1-.8-.3-.2-.2-.3-.5-.3-.8-.2-.4-.1-.6-.1-.8zm3.8-9.1c.2.2.3.5.3.8s-.1.6-.3.8c-.2.2-.5.3-.8.3s-.6-.1-.8-.3-.3-.5-.3-.8.1-.5.3-.8.5-.3.8-.3.6.1.8.3z"
        />
      </g>
    </svg>
    <div v-if="isTooltipShown" class="k-tooltip" v-html="$t([stepsModule.state.stepCurrent.type, 'tooltip'])">
    </div>
  </div>
</template>

<script lang="ts">
import { StepsModule } from '@/composables/use-steps';
import { TranslationHandler } from '@/mixins/translation-handler';
import { mixins, Options, Vue, prop } from 'vue-class-component';

class Props {
  stepsModule = prop({
    type: Object as () => StepsModule,
    required: true,
  })
}

@Options({})
export default class Overlay extends mixins(Vue.props(Props), TranslationHandler) {
  isTooltipShown: boolean = false

  $refs!: {
    tooltip: HTMLElement,
  }

  mounted() {
    document.addEventListener('click', this.bindClick)
  }

  unmounted() {
    document.removeEventListener('click', this.bindClick)
  }

  bindClick(event: Event) {
    const $tooltip: HTMLElement | undefined = this.$refs.tooltip

    if ($tooltip === undefined || this.isTooltipShown === false) { return }

    if ($tooltip.contains((event.target as HTMLElement)) === false) {
      this.isTooltipShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

// prefixed the class with `k-` to avoic conflics with the wordpress `.tooltip`
.k-tooltip {
  position: absolute;
  top: 130%;
  background: $blue-2;
  font-size: 16px;
  color: white;
  max-width: 350px;
  z-index: 1;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  font-weight: 400;
  right: -20px;
  width: 200px;
  line-height: 22px;
  letter-spacing: 0;

  @media only screen and (min-width: 960px) {
    left: -20px;
    right: initial;
  }

  &__icon {
    width: 20px;
    cursor: pointer;
  }

  &__wrapper {
    position: relative;
    display: inline-block;
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    right: 20px;
    top: -10px;
    position: absolute;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $blue-2 transparent;

    @media only screen and (min-width: 960px) {
      left: 20px;
    }
  }
}
</style>
