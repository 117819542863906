import { STEPS, STEP_ROUTE_STEPS_BRANCH, STEP_ROUTE_LONG_PRODUCT_TYPE, STEP_ROUTE_LONG_STEPS, STEP_ROUTE_SHORT_STEPS, STEP_ROUTE_LONG_STEPS_MACHINE_SKIP, STEP_SELECT_OPTION_NOT_INSTANT } from '@/config/steps'
import { IHomeStyle, IMap, IPreselectStepRoute, IStep, IStepLayout, IStepSelect, IStepSelectElectricity, IStepSelectProductType, IStepSelectSkip, IStepsSelected, IStepType } from '@/types'
import { reactive, computed } from 'vue'

interface State {
  stepCurrentIndex: number
  stepCurrent: IStep,
  stepProgressPercentage: number
  selected: IStepsSelected
  steps: IStep[]
  isNextAllowed: boolean
  isBackAllowed: boolean
  imageMap: IMap
  textMap: IMap
  homeStyle: IHomeStyle
  preselectStepRoute: IPreselectStepRoute
  isRouteLongLocked: boolean
  isIndexChangeLocked: boolean
  isOverlayActive: boolean
  isContactSkipped: boolean
  routeNumber: number
}

export interface StepsModule {
  state: State
  next: () => void
  nextContact: (index: number) => void
  backHome: () => void
  back: () => void
  toggleOption: (option: IStepSelect, step: IStep) => void
}

export function useSteps(imageMap: IMap, homeStyle: IHomeStyle, textMap: IMap, preselectMap: IMap, preselectStepRoute: IPreselectStepRoute): StepsModule {
  const state: State = reactive({
    imageMap,
    homeStyle,
    textMap,
    preselectStepRoute,
    selected: (preselectMap as IStepsSelected) || {},
    stepCurrentIndex: 0,
    stepCurrent: computed((): IStep => {
      return state.steps[state.stepCurrentIndex]
    }),
    stepProgressPercentage: computed((): number => {
      return ((state.stepCurrentIndex + 1) / stepProgressCount.value * 100)
    }),
    steps: computed((): IStep[] => {
      // @TODO all with fromentries
      if (preselectStepRoute === IPreselectStepRoute.BRANCH) {
        return STEP_ROUTE_STEPS_BRANCH.map((type) => (
          Object.fromEntries(STEPS.map((step) => [step.type, step]))[type]
        )).map((step) => ({
          ...step,
          layout: step.type === IStepType.BRANCH ? IStepLayout.HOME : IStepLayout.STEP_EMPTY
        }))
      }

      if (stepRouteShortIsActive.value === true && state.isRouteLongLocked === false) {
        return STEPS.filter((step) => (
          STEP_ROUTE_SHORT_STEPS.includes(step.type)
        ))
      }

      if (stepRouteLongNoMachineSkipIsActive.value === true && state.isRouteLongLocked === false) {
        return STEPS.filter((step) => (
          STEP_ROUTE_LONG_STEPS_MACHINE_SKIP.includes(step.type)
        ))
      }

      return STEPS.filter((step) => (
        STEP_ROUTE_LONG_STEPS.includes(step.type)
      ))
    }),
    isNextAllowed: computed((): boolean => {
      return (state.selected[state.stepCurrent.type] || []).length > 0
        || state.stepCurrent.type === IStepType.SPLASH
        || state.stepCurrent.type === IStepType.OVERVIEW
        || state.stepCurrent.type === IStepType.RESULT
    }),
    isBackAllowed: computed((): boolean => {
      return state.stepCurrent.type !== IStepType.PRODUCT_TYPE
    }),
    isRouteLongLocked: false,
    isIndexChangeLocked: false,
    isOverlayActive: computed((): boolean => {
      // branch short route
      if (
           state.stepCurrent.type === IStepType.BRANCH
        && stepRouteShortIsActive.value === true
        && state.preselectStepRoute !== IPreselectStepRoute.BRANCH
      ) {
        return true
      }

      if (state.stepCurrent.type === IStepType.ELECTRICITY) {
        const electricitySelected = (state.selected[IStepType.ELECTRICITY] as IStepSelectElectricity[]) || []

        return !!electricitySelected.find(el =>
          STEP_SELECT_OPTION_NOT_INSTANT.includes(el)
        )
      }

      return false
    }),
    isContactSkipped: false,
    routeNumber: computed((): number => {
      if (state.isContactSkipped === true) {
        return 3
      }
      if (stepRouteShortIsActive.value === true) {
        return 2
      }
      return 1
    })
  })

  const stepProgressCount = computed((): number => {
    return state.steps.filter((s) => s.type !== IStepType.THANK_YOU).length // thank you page is not the progress
  })

  const stepRouteShortIsActive = computed((): boolean => {
    // if product type is not hot-drinks or cold-drinks
    const selectedProductTypes = (state.selected[IStepType.PRODUCT_TYPE] as IStepSelectProductType[]) || []

    return !selectedProductTypes.find((type: IStepSelectProductType) =>
      STEP_ROUTE_LONG_PRODUCT_TYPE.includes(type)
    )
  })

  const stepRouteLongNoMachineSkipIsActive = computed((): boolean => {
    // if machine is skip
    if (state.selected[IStepType.MACHINE_TYPE] === undefined) {
      return false
    }

    return state.selected[IStepType.MACHINE_TYPE].includes(IStepSelectSkip)
  })

  function next() {
    if (state.isIndexChangeLocked || state.steps.length === state.stepCurrentIndex + 1) {
      return
    }

    if (state.steps[state.stepCurrentIndex + 1] && state.steps[state.stepCurrentIndex + 1].type === IStepType.OVERVIEW) {
      // lock the long route on overview page
      state.isRouteLongLocked = true

      // workaround to make sure overview always has long route
      if (stepRouteLongNoMachineSkipIsActive.value === true) {
        state.stepCurrentIndex += 2
        return
      }
    }

    state.stepCurrentIndex += 1
  }

  function back() {
    if (state.isIndexChangeLocked) return

    state.stepCurrentIndex -= 1
  }

  function nextContact() {
    if (state.isIndexChangeLocked) return

    state.isContactSkipped = true

    const index = state.steps
      .findIndex((s) => s.type === IStepType.CONTACT_LONG || s.type === IStepType.CONTACT_SHORT)

    if (index >= 0) {
      state.stepCurrentIndex = index
    }
  }

  function backHome() {
    state.stepCurrentIndex = 0
    state.selected = {}
  }

  function toggleOption(option: IStepSelect, step: IStep) {
    if (state.selected[step.type] === undefined) {
      state.selected[step.type] = []
    }

    if (state.selected[step.type].includes(option)) {
      removeOption(option, step)
    } else {
      addOption(option, step)
    }
  }

  function removeOption(option: IStepSelect, step: IStep) {
    if (step.isMultiple) {
      state.selected[step.type].splice(state.selected[step.type].indexOf(option), 1)
      return
    }

    state.selected[step.type] = []
  }

  function addOption(option: IStepSelect, step: IStep) {
    // single select function
    if (option === IStepSelectSkip || state.selected[step.type].includes(IStepSelectSkip)) {
      state.selected[step.type] = []
    }

    if (step.isMultiple) {
      state.selected[step.type].push(option)
      return
    }

    state.selected[step.type] = [option]
  }

  return {
    state,
    next,
    back,
    toggleOption,
    nextContact,
    backHome,
  }
}
