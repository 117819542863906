<template>
  <div class="textarea" :class="{ 'textarea--error': error }">
    <textarea v-model="text" rows="5"></textarea>
    <label>{{ placeholder }}</label>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

class Props {
  placeholder = prop({
    type: String,
    required: true,
  })

  value = prop({
    type: String,
    required: true,
  })

  error = prop({
    type: Boolean,
    default: false,
  })
}

@Options({
  emits: ['on-change'],
  watch: {
    text(text: string) {
      this.$emit('on-change', text)
    }
  }
})
export default class Textarea extends Vue.props(Props) {
  text: string = ''

  created() {
    this.text = this.value
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/setup.scss";

.textarea {
  position: relative;
  border: 2px solid $gray-1;
  /* padding: 0 20px; */
  align-items: center;
  cursor: pointer;
  @media only screen and (min-width: 960px) {
    &:hover {
      border: 2px solid $gray-2;
    }
  }
  textarea {
    transition: all 0.2s linear;
    font-size: 14px;
    color: $black;
    outline: none;
    border: 0;
    box-sizing: border-box;
    padding: 30px 20px 20px;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-family: Lavazza, Helvetica, Arial, sans-serif;

    &:not(:empty) {
      color: pink;
    }
    &:focus ~ label,
    &:active ~ label {
      top: 8px;
      transform: translateY(0);
      font-size: 12px;
    }
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: $gray-2;
    position: absolute;
    left: 20px;
    top: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
  }

  &--error {
    border-color: $red;
  }
}
</style>
