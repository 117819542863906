import { TRANSLATIONS } from '@/config/translations'
import { IStepTemplate } from '@/types'
import { Options, Vue } from 'vue-class-component'

@Options({})
export class TranslationHandler extends Vue {
  translations = TRANSLATIONS

  $t(keys: (string | IStepTemplate)[], replaceMap: { [k: string]: string } = {}) {
    let val: any = this.translations

    keys.forEach((key) => {
      if (val[key] === undefined) {
        val = keys.join(',')
        return
      }

      val = val[key]
    })

    Object.keys(replaceMap).forEach((key) => {
      val = val.replace(`{${key}}`, replaceMap[key])
    })

    return val
  }
}
